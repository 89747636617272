<template>
  <div class="forget">
    <div class="forget-headline">
      <div class="headline-left" @click="forgetBack">
        <img src="../assets/system-14.png" alt="" />
      </div>
      <div class="headline-title">忘记密码</div>
      <div class="headline-left"></div>
    </div>
    <div class="forget-headline-lint"></div>

    <div class="forget-block">
      <!-- <div class="block-list">
        <div class="list-left">账号名</div>
        <div class="list-center">
          <div class="center-input">
            <van-field v-model="oldName" placeholder="请输入账号名" />
          </div>
        </div>
        <div class="list-right"></div>
      </div>
      <div class="block-div">账号仅支持中英文、数字，最长10个汉字长度</div> -->
      <div class="block-list">
        <div class="list-left">手机号</div>
        <div class="list-center" style="width: 80%">
          <div class="center-input">
            <van-field
              v-model="oldPhone"
              placeholder="请输入账号绑定的手机号"
            />
          </div>
        </div>
      </div>
      <div class="block-list">
        <div class="list-left">验证码</div>
        <div class="list-center" style="width: 54%">
          <div class="center-input">
            <van-field v-model="oldVerification" placeholder="请输入验证码" />
          </div>
        </div>
        <div class="list-right">
          <div
            class="right-verification"
            :style="{
              color: oldPhone != '' ? '#fff' : '',
              background: oldPhone != '' ? '#2481D7' : '',
            }"
            @click="verificationClick"
          >
            {{ count == 0 ? "获取验证码" : count + "秒" }}
          </div>
        </div>
      </div>
      <div class="block-list">
        <div class="list-left">新密码</div>
        <div class="list-center" style="width: 80%">
          <div class="center-input">
            <el-input
              v-model="newPassword"
              placeholder="请输入新密码"
              show-password
            ></el-input>
          </div>
        </div>
      </div>
      <div class="block-div">密码共8位，需包含大小写字母、数字</div>
      <div class="block-list">
        <div class="list-left">确认密码</div>
        <div class="list-center" style="width: 80%">
          <div class="center-input">
            <el-input
              v-model="affirmPassword"
              placeholder="请确认新密码"
              show-password
            ></el-input>
          </div>
        </div>
      </div>
    </div>

    <div
      class="forget-affirm"
      :class="{
        'affirm-complete':
          oldPhone != '' &&
          oldVerification != '' &&
          newPassword != '' &&
          affirmPassword != '',
      }"
      @click="forgetPassword"
    >
      确认修改
    </div>

    <!--弹出框-->
    <van-popup v-model="show" round :close-on-click-overlay="false">
      <div class="forget-popup">
        <img class="popup-img" src="../assets/personage-11.png" alt="" />
        <div class="popup-title">密码重置成功</div>
        <div class="popup-text">密码已重置成功，您可以去登录了</div>
        <div class="popup-button" @click="confirmShows">确定</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  userRegister,
  userforget,
  userforgetup,
  userPhone,
  postcode,
} from "../api/login";

import { userInfoGet } from "../api/index";
var throttle = require("../plugins/throttle"); // 防抖
var verify = require("../plugins/verify"); // 手机号校验
import { md5 } from "../plugins/md5";

export default {
  data() {
    return {
      oldName: "", // 账号名
      oldPhone: "",
      oldVerification: "", // 旧的验证码
      newPassword: "",
      affirmPassword: "",
      //--------
      count: 0, // 旧的验证码倒计时
      timer: null, // 旧的验证码倒计时
      //-------------------------------
      show: false,
    };
  },
  created() {},
  mounted() {
    this.backTop()
    window.onload = function () {
      document.addEventListener("touchstart", function (e) {
        if (e.touches.length > 1) {
          e.preventDefault();
        }
      });
      document.addEventListener("gesturestart", function (e) {
        e.preventDefault();
      });
    };
  },
  methods: {
    backTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    forgetPassword() {
      if (
        this.oldPhone == "" ||
        this.oldVerification == "" ||
        this.newPassword == "" ||
        this.affirmPassword == ""
      ) {
        this.$message.warning(
          this.oldPhone == ""
            ? "请先输入账号绑定的手机号！"
            : this.oldVerification == ""
            ? "请先输入验证码！"
            : this.newPassword == ""
            ? "请先输入新密码！"
            : this.affirmPassword == ""
            ? "请先确认新密码！"
            : ""
        );
        return;
      }

      if (this.newPassword != this.affirmPassword) {
        this.$message.warning("两次密码不一致");
        return;
      }

      let params = {
        code: this.oldVerification,
        password: md5(this.newPassword),
        phone: this.oldPhone,
        username: this.oldName,
      };

      userforget(params).then((res) => {
        if (res.code == 200) {
            this.show = true
        }
      });
    },
    verificationClick() {
      if (this.count != 0) {
        return;
      }

      if (this.oldPhone == "") {
        this.$message.warning("请先输入手机号！");
        return;
      }

      if (!verify.codeVerification(this.oldPhone)) {
        this.$message.warning("手机号格式有误！");
        return;
      }

      this.generationVerificationMet(0);
    },
    generationVerificationMet: throttle.throttle(function (index) {
      let params = {
        phone: this.oldPhone,
        type: 2,
      };

      postcode(params).then((res) => {
        if (res.message == "") {
          if (index == 0) {
            //倒计时时间
            const TIME_COUNT = 60;
            if (!this.timer) {
              this.count = TIME_COUNT;
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                  this.count--;
                } else {
                  this.show = true;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
          }
        }
      });
    }, 2000),
    //------------
    forgetBack() {
      this.$router.go(-1);
    },
    confirmShows() {
      this.show = false;
      localStorage.clear();
      this.forgetBack();
    },
  },
};
</script>

<style lang="less" scoped>
.forget {
  min-height: 100vh;
  background: #f5f5f5;
  position: relative;

  .forget-headline {
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .headline-left {
      width: 25px;
      height: 25px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .headline-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 17px;
      color: #222222;
    }
  }

  .forget-headline-lint {
    height: 47px;
    width: 100%;
  }

  .forget-block {
    .block-list {
      padding: 0 12px;
      box-sizing: border-box;
      background: #fff;
      height: 58px;
      width: 100%;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ececed;

      .list-left {
        width: 20%;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 15px;
        color: #222222;
      }

      .list-center {
        .center-title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 15px;
          color: #222222;
        }

        .center-input {
          /deep/ .van-cell {
            padding: 0;
            background: rgba(255, 255, 255, 0);
          }

          /deep/ .van-field__control::placeholder {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 15px;
            color: #888888;
          }

          /deep/ .el-input__inner {
            padding: 0;
            border: 0;
          }

          /deep/ .el-input__inner::placeholder {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 15px;
            color: #888888;
          }
        }
      }

      .list-right {
        width: 20%;
        margin-left: 2%;
        .right-verification {
          width: 85px;
          height: 31px;
          background: #e0e0e0;
          border-radius: 15.5px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 12px;
          color: rgba(255, 255, 255, 1);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .block-list:last-child {
      border-bottom: 0;
    }

    .block-div {
      padding: 0 12px;
      box-sizing: border-box;
      height: 36px;
      display: flex;
      align-items: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      background: #f5f5f5;
      color: #ff5858;
    }
  }

  .forget-affirm {
    margin: 20px 10px 0;
    height: 46px;
    background: #e6e6e6;
    border-radius: 5px;
    font-family: PingFang-SC, PingFang-SC;
    font-weight: 800;
    font-size: 15px;
    color: #c5c5c5;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1px;
  }

  .affirm-complete {
    background: linear-gradient(90deg, #609ce6 0%, #5084da 100%);
    color: #ffffff;
  }

  //------------
  .forget-popup {
    width: 259px;
    padding: 20px 0;
    box-sizing: border-box;
    background: #ffffff;
    text-align: center;

    .popup-img {
      width: 40px;
      height: 40px;
    }

    .popup-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 17px;
      color: #333333;
      margin: 15px 0 12px;
    }

    .popup-text {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #666666;
    }

    .popup-button {
      width: 136px;
      height: 35px;
      background: #2481d7;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 15px;
      color: #ffffff;
      margin: 21px auto 0;
    }
  }
}
</style>