<template>
  <div class="information">
    <div class="information-first">
      <nav-bar></nav-bar>
    </div>
    <div class="information-second">
      <img class="information-second-back" src="../assets/information1.png" />
      <div class="information-second-block">
        <div class="information-second-block-title">资讯动态</div>
        <div class="information-second-block-text">DYNAMIC INFORMATION</div>
      </div>
    </div>

    <div class="information-thirdly">
      <div
        class="information-thirdly-list"
        v-for="(item, index) in 6"
        :key="index"
        @click="herfall(index)"
      >
        <div class="information-thirdly-list-left">
          <div class="information-thirdly-list-left-title">
            {{
              index == 0
                ? "26"
                : index == 1
                ? "25"
                : index == 2
                ? "25"
                : index == 3
                ? "24"
                : index == 4
                ? "18"
                : "13"
            }}
          </div>
          <div class="information-thirdly-list-left-text">
            {{
              index == 0
                ? "2022年10月"
                : index == 1
                ? "2022年10月"
                : index == 2
                ? "2022年10月"
                : index == 3
                ? "2022年10月"
                : index == 4
                ? "2022年10月"
                : "2022年10月"
            }}
          </div>
        </div>
        <div class="information-thirdly-list-right">
          <div class="information-thirdly-list-right-title">
            {{
              index == 0
                ? "国家外汇管理局2023年度公务员考录工作公告"
                : index == 1
                ? "人民银行党委、外汇局党组认真传达学习党的二十大精神"
                : index == 2
                ? "人民银行党委、外汇局党组认真传达学习党的二十大精神"
                : index == 3
                ? "国家外汇管理局党员干部认真收听收看党的二十大开幕盛况"
                : index == 4
                ? "十问中国经济三季报！国家统计局10位司局长解读"
                : "深化服务贸易外汇管理改革 谱写高水平开放新篇章"
            }}
          </div>

          <div class="information-thirdly-list-right-display">
            <div class="information-thirdly-list-right-display1">
              {{
                index == 0
                  ? "国家外汇管理局是中国人民银行管理的依法进行外汇管理的国家局。2023年度我局计划招录4名公务员。国家外汇管理局公务员考录工作按规定由国家公务员局统一组织。考生可登录中央机关及其直属机构2023年度考试录用公务员专题网站（http://bm.scs.gov.cn/kl2023）进行网上报名"
                  : index == 1
                  ? "人民银行党委、外汇局党组召开扩大会议，认真传达学习党的二十大和党的二十届一中全会精神，研究部署贯彻落实工作。人民银行党委书记、副行长郭树清主持会议，党委副书记、行长易纲，人民银行党委委员、副行长、外汇局党组书记、局长潘功胜及各位党委委员、党组成员参加会议。"
                  : index == 2
                  ? "2022年10月24日，人民银行党委、外汇局党组召开扩大会议，认真传达学习党的二十大和党的二十届一中全会精神，研究部署贯彻落实工作。人民银行党委书记、副行长郭树清主持会议，党委副书记、行长易纲，人民银行党委委员、副行长、外汇局党组书记、局长潘功胜及各位党委委员、党组成员参加会议。"
                  : index == 3
                  ? "10月16日上午，中国共产党第二十次全国代表大会在北京隆重开幕。习近平总书记代表第十九届中央委员会向大会作题为《高举中国特色社会主义伟大旗帜 为全面建设社会主义现代化国家而团结奋斗》国家外汇管理局组织机关党员干部认真收听收看大会开幕盛况"
                  : index == 4
                  ? "国家统计局24日公布，初步核算，前三季度国内生产总值870269亿元，按不变价格计算，同比增长3.0%，比上半年加快0.5个百分点。前三季度经济数据怎么看？经济运行有哪些亮点？国家统计局10位司局长为你解读"
                  : "党的十八大以来，在党中央坚强领导下，我国服务业改革开放持续深化，服务贸易日益成为对外贸易发展的新引擎、对外深化开放的新动力。外汇局坚决贯彻落实党中央国务院决策部署，全面深化服务贸易外汇管理改革，坚持改革赋能、服务民生，助推我国服务贸易高质量发展。"
              }}
            </div>
            <img
              class="information-thirdly-list-right-display2"
              :src="
                index == 0
                  ? require('../assets/information1-1.png')
                  : index == 1
                  ? require('../assets/information1-2.png')
                  : index == 2
                  ? require('../assets/information1-3.png')
                  : index == 3
                  ? require('../assets/information1-4.png')
                  : index == 4
                  ? require('../assets/information1-5.png')
                  : require('../assets/information1-6.png')
              "
            />
          </div>
        </div>
      </div>
    </div>
    <div class="information-seventh">
      <tab-bar></tab-bar>
    </div>
  </div>
</template>


<script>
import navBar from "../components/nav-bar.vue";
import tabBar from "../components/tab-bar.vue";

export default {
  components: { navBar, tabBar },
  mounted() {
    this.backTop();
    window.onload = function () {
      document.addEventListener("touchstart", function (e) {
        if (e.touches.length > 1) {
          e.preventDefault();
        }
      });
      document.addEventListener("gesturestart", function (e) {
        e.preventDefault();
      });
    };
  },
  methods: {
    herfall(index) {
      if (index == 0) {
        window.open(
          "  http://www.safe.gov.cn/safe/2022/1026/21625.html",
          "_blank"
        );
      } else if (index == 1) {
        window.open(
          "http://www.safe.gov.cn/safe/2022/1025/21615.html",
          "_blank"
        );
      } else if (index == 2) {
        window.open(
          "  http://www.safe.gov.cn/safe/2022/1025/21617.html",
          "_blank"
        );
      } else if (index == 3) {
        window.open(
          " http://www.safe.gov.cn/safe/2022/1024/21606.html",
          "_blank"
        );
      } else if (index == 4) {
        window.open(
          "http://www.safe.gov.cn/safe/2022/1018/21559.html",
          "_blank"
        );
      } else if (index == 5) {
        window.open(
          "http://www.safe.gov.cn/safe/2022/1011/21531.html",
          "_blank"
        );
      }
    },
    backTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.information {
  .information-first {
    height: 50px;
    width: 100%;
  }
  .information-second {
    width: 100%;
    position: relative;
    height: 128.5px;
    padding-top: 40px;
    box-sizing: border-box;
    .information-second-back {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: fill;
      display: flex;
      top: 0;
    }
    .information-second-block {
      height: 37px;
      position: relative;
      display: flex;
      justify-content: center;
      .information-second-block-title {
        font-size: 27.5px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 600;
        color: #ffffff;
      }
      .information-second-block-text {
        font-size: 17.5px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        position: absolute;
        bottom: -5px;
        opacity: 0.3;
        letter-spacing: 1px;
      }
    }
  }
  .information-thirdly {
    margin: 0 15px;
    padding-bottom: 40px;
    .information-thirdly-list {
      display: flex;
      justify-content: space-between;
      padding-bottom: 25px;
      border-bottom: 1px solid #e8e8e8;
      box-sizing: border-box;
      margin-top: 20.5px;
      .information-thirdly-list-left {
        .information-thirdly-list-left-title {
          font-size: 25px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
        .information-thirdly-list-left-text {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          margin-top: 2px;
        }
      }
      .information-thirdly-list-right {
        width: 258.5px;
        .information-thirdly-list-right-title {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 22.5px;
        }
        .information-thirdly-list-right-display {
          display: flex;
          justify-content: space-between;
          margin-top: 5.5px;
          .information-thirdly-list-right-display1 {
            font-size: 8px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 17px;
            height: 66.5px;
            word-break: break-all;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            width: 150px;
          }
          .information-thirdly-list-right-display2 {
            width: 98px;
            height: 66.5px;
          }
        }
      }
    }
  }
}
</style>