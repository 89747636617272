<template>
  <div class="particulars">
    <img
      class="particulars-back"
      @click="particularsBack"
      src="../assets/system-7.png"
      alt=""
    />

    <div class="particulars-swiper">
      <van-swipe
        :autoplay="3000"
        indicator-color="white"
        @change="onswiperChange"
      >
        <van-swipe-item v-for="(item, index) in imgsList" :key="index">
          <img
            class="swiper-img"
            :src="item"
            alt=""
            @click="previewStop(index)"
          />
        </van-swipe-item>
        <div class="custom-indicator" slot="indicator">
          {{ current + 1 }} / {{ imgsList.length }}
        </div>
      </van-swipe>
    </div>

    <div class="particulars-headline">
      <div class="headline-price">
        <div class="price-new"><span>¥</span>{{ goodssellingPrice }}</div>
        <div class="price-old">¥ {{ goodsdiscountPrice }}</div>
      </div>

      <div class="headline-title">{{ merchandiseGoodsObj.name }}</div>
      <!-- <div class="headline-text">{{  }}</div> -->
    </div>

    <div class="particulars-lint"></div>

    <div class="particulars-validity">
      <div class="validity-title">有效期选择</div>

      <div class="validity-display">
        <div
          class="display-list"
          v-for="(item, index) in merchandiseGoodsList"
          :key="index"
          :class="{ validityActive: index == validityIndex }"
          @click="validityMet(index)"
        >
          <span v-if="item.termId == 31">1个月</span>
          <span v-if="item.termId == 93">3个月</span>
          <span v-if="item.termId == 186">6个月</span>
          <span v-if="item.termId == 366">1年</span>
          <span v-if="item.termId == 732">2年</span>
          <span v-if="item.termId == 1098">3年</span>
        </div>
      </div>

      <div class="validity-lint"></div>

      <div class="validity-landscape">
        <div class="landscape-left">数量</div>
        <div class="landscape-right">
          <div class="right-minus">
            <img
              :src="
                amountNum == 1
                  ? require('../assets/merchandise-minus-c.png')
                  : require('../assets/merchandise-minus.png')
              "
              alt=""
              @click="minusAdd(0)"
            />
          </div>

          <div class="right-quantity">
            {{ amountNum }}
          </div>

          <div class="right-minus" @click="minusAdd(1)">
            <img src="../assets/merchandise-add.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="particulars-lint"></div>

    <div class="particulars-parameter">
      <div class="parameter-title">基本参数</div>
      <div class="parameter-text" v-for="(item, index) in 4" :key="index">
        {{
          index == 0
            ? "商品名称"
            : index == 1
            ? "商品编号"
            : index == 2
            ? "运行环境"
            : "软件大小"
        }}

        <span>
          {{
            index == 0
              ? merchandiseGoodsObj.name
              : index == 1
              ? merchandiseGoodsObj.goodCode
              : index == 2
              ? "Windows"
              : "500M"
          }}
        </span>
      </div>
    </div>

    <div class="particulars-lint"></div>

    <div class="particulars-rich">
      <div class="rich-title">商品详情</div>
      <div class="rich-text" v-html="merchandiseGoodsObj.contentText"></div>
    </div>

    <div class="particulars-purchase" @click="placeMet">立即购买</div>
  </div>
</template>

<script>
import { usergoodtypeIdGet, usergoodlistGet, userbuyPost } from "../api/index";
import { ImagePreview } from "vant";

export default {
  data() {
    return {
      amountNum: 1, // 数量
      current: 0,
      //--------------------
      indexId: "",
      merchandiseGoodsObj: {},
      imgsList: [],
      merchandiseGoodsList: [],
      //-------------
      validityIndex: 0,
      goodssellingPrice: "",
      goodsdiscountPrice: "",
      goodsdiscountObj: {},
    };
  },
  created() {
    this.indexId = this.$route.query.id;
    this.merchandiseMet();
    //-----------获取列表
    // this.usergoodlistGetMet();
  },
  mounted() {
    this.backTop()
    window.onload = function () {
      document.addEventListener("touchstart", function (e) {
        if (e.touches.length > 1) {
          e.preventDefault();
        }
      });
      document.addEventListener("gesturestart", function (e) {
        e.preventDefault();
      });
    };
  },
  methods: {
    backTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    merchandiseMet() {
      let params = this.indexId;

      usergoodtypeIdGet(params).then((res) => {
        if (res.code == 200) {
          const regex = new RegExp("<img", "gi");
          res.result.good.contentText = res.result.good.contentText.replace(
            regex,
            `<img style="max-width: 100%; height: 100%"`
          );

          this.merchandiseGoodsObj = res.result.good;
          let array =
            res.result.good.goodUrl == null
              ? []
              : res.result.good.goodUrl.split(",");
          let list = [];
          array.forEach((item, index) => {
            item = "https://gongafter.gjlike.com/jeecg-boot/" + item;
            list.push(item);
          });

          this.imgsList = list;

          //   this.imgs =
          //     "https://gongafter.gjlike.com/jeecg-boot/" +
          //     res.result.good.goodUrl;
          this.merchandiseGoodsList = res.result.goodDetail;
          this.validityMet(0);
        }
      });
    },
    validityMet(index) {
      this.validityIndex = index;
      this.goodssellingPrice = this.merchandiseGoodsList[index].price;
      this.goodsdiscountPrice = this.merchandiseGoodsList[index].marketPrice;
      this.goodsdiscountObj = this.merchandiseGoodsList[index];
    },
    //-----------------------
    particularsBack() {
      this.$router.go(-1);
    },
    onswiperChange(e) {
      this.current = e;
    },
    minusAdd(index) {
      if (index == 0) {
        if (this.amountNum <= 1) {
          return;
        }

        this.amountNum--;
      } else {
        this.amountNum++;
      }
    },
    previewStop(index) {
      ImagePreview({
        images: this.imgsList,
        showIndex: true,
        loop: false,
        startPosition: index,
      });
    },
    placeMet() {
      this.$router.push({
        path: "/systemParticularsPlace",
        query: {
          id: this.indexId,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.particulars {
  .particulars-back {
    position: fixed;
    top: 20.5px;
    left: 10px;
    width: 31px;
    height: 31px;
    z-index: 10;
  }

  .particulars-swiper {
    position: relative;
    height: 400px;

    .swiper-img {
      width: 100%;
      height: 400px;
      object-fit: cover;
    }

    .custom-indicator {
      position: absolute;
      bottom: 20px;
      right: 20px;
      z-index: 10;
      padding: 5px 8px;
      box-sizing: border-box;
      background: #000000;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 18px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #ffffff;
    }
  }
  .particulars-headline {
    padding: 20px 10px;
    box-sizing: border-box;
    .headline-price {
      display: flex;
      align-items: center;
      .price-new {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 26px;
        color: #ff6737;

        span {
          font-size: 18px;
          margin-right: 5px;
        }
      }
      .price-old {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 15px;
        color: #999999;
        margin-left: 9px;
        text-decoration: line-through;
      }
    }

    .headline-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #333333;
      margin: 10px 0 0;
    }

    .headline-text {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #666666;
    }
  }

  .particulars-lint {
    height: 10px;
    background: #f5f5f5;
  }

  .particulars-validity {
    padding: 10px 12px 25px;
    box-sizing: border-box;
    .validity-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 15px;
      color: #333333;
    }

    .validity-display {
      margin-top: 12.5px;
      display: flex;
      flex-wrap: wrap;
      .display-list {
        width: calc((100% / 3) - 10px);
        height: 29px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #666666;
        border: 1px solid #d2d2d2;
        margin-right: 11px;
        margin-bottom: 12px;
        border-radius: 3px;
      }

      .display-list:nth-child(3n + 3) {
        margin-right: 0;
      }

      .validityActive {
        border: 1px solid #2481d7;
        color: #2481d7;
      }
    }

    .validity-lint {
      height: 1px;
      background: #e4e4e4;
    }

    .validity-landscape {
      margin-top: 22.5px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .landscape-left {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 15px;
        color: #333333;
      }

      .landscape-right {
        display: flex;
        align-items: center;

        .right-minus {
          width: 29px;
          height: 29px;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .right-quantity {
          margin: 0 7px;
          background: #f2f2f2;
          padding: 5px 25px;
          box-sizing: border-box;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 15px;
          color: #222222;
        }
      }
    }
  }

  .particulars-parameter {
    padding: 10px 12px 15px;
    box-sizing: border-box;

    .parameter-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 15px;
      color: #333333;
      margin-bottom: 10px;
    }
    .parameter-text {
      margin-bottom: 11px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 13px;
      color: #999999;

      span {
        margin-left: 12px;
        color: #333333;
      }
    }
  }

  .particulars-rich {
    padding: 10px 12px 15px;
    box-sizing: border-box;
    .rich-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 15px;
      color: #333333;
    }

    .rich-text {
      word-break: break-all;

      p {
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .particulars-purchase {
    margin: 8px 15px;
    height: 45px;
    background: linear-gradient(90deg, #609ce6 0%, #5084da 100%);
    border-radius: 5px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 1px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>