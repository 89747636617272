<template>
  <div class="joinus">
    <div class="joinus-first">
      <nav-bar></nav-bar>
    </div>
    <div class="joinus-second">
      <img class="joinus-second-back" src="../assets/joinus1.png" />
      <div class="joinus-second-block">
        <div class="joinus-second-block-title">风控讲堂</div>
        <div class="joinus-second-block-text">RISK MANAGEMENT</div>
      </div>
    </div>

    <div class="joinus-thirdly">
      <div class="joinus-thirdly-title">一起成长，追梦理想</div>
      <div class="joinus-thirdly-text">
        共事最亲民的老板，遇见最极客的技术牛人加入富有激情、勇于创新的青年团队
      </div>

      <div class="joinus-thirdly-list" v-for="(item, index) in 3" :key="index">
        <img
          class="joinus-thirdly-list-left"
          :src="
            index == 0
              ? require('../assets/home-swiper-5-1.png')
              : index == 1
              ? require('../assets/home-swiper-5-2.png')
              : require('../assets/home-swiper-5-3.png')
          "
          alt=""
        />
        <div class="joinus-thirdly-list-right">
          <div class="joinus-thirdly-list-right-title">
            {{ index == 0 ? "薪资待遇" : index == 1 ? "员工福利" : "职业规划" }}
          </div>
          <div class="joinus-thirdly-list-right-text">
            {{
              index == 0
                ? "业内有竞争力的薪酬、激励奖金计划、年终优秀员工奖励制度，对优秀的人才将给予公司股票期权，让每一位员工充分实现自身的价值。"
                : index == 1
                ? "五险一金、带薪假期、年度体检、年度旅游、团队聚餐、多样化的文体娱乐活动与舒适的办公环境。快乐地工作生活，我们不断在努力。"
                : "入职培训、技能培训、定期的内部分享交流与团队拓展培训。公司处于快速发展期与扩大阶段，我们为优秀的员工提供岗位晋升的机会。 "
            }}
          </div>
        </div>
      </div>
    </div>

    <div class="joinus-seventh">
      <tab-bar></tab-bar>
    </div>
  </div>
</template>

<script>
import navBar from "../components/nav-bar.vue";
import tabBar from "../components/tab-bar.vue";

export default {
  components: { navBar, tabBar },
  mounted() {
    this.backTop();
    window.onload = function () {
      document.addEventListener("touchstart", function (e) {
        if (e.touches.length > 1) {
          e.preventDefault();
        }
      });
      document.addEventListener("gesturestart", function (e) {
        e.preventDefault();
      });
    };
  },
  methods: {
    backTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  }
  
};
</script>

<style lang="less" scoped>
.joinus {
  .joinus-first {
    height: 50px;
    width: 100%;
  }

  .joinus-second {
    width: 100%;
    position: relative;
    height: 128.5px;
    padding-top: 40px;
    box-sizing: border-box;
    .joinus-second-back {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: fill;
      display: flex;
      top: 0;
    }
    .joinus-second-block {
      height: 37px;
      position: relative;
      display: flex;
      justify-content: center;
      .joinus-second-block-title {
        font-size: 27.5px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 600;
        color: #ffffff;
      }
      .joinus-second-block-text {
        font-size: 17.5px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        position: absolute;
        bottom: -5px;
        opacity: 0.3;
        letter-spacing: 1px;
      }
    }
  }
  .joinus-thirdly {
    margin: 0 24px;
    padding: 30px 0;
    box-sizing: border-box;

    .joinus-thirdly-title {
      font-size: 27.5px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #2481d7;
      text-align: center;
    }
    .joinus-thirdly-text {
      width: 266px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      margin: 0 auto;
      text-align: center;
      margin-top: 12px;
      margin-bottom: 30px;
    }
    .joinus-thirdly-list {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      .joinus-thirdly-list-left {
        width: 75px;
        height: 75px;
      }
      .joinus-thirdly-list-right {
        width: 239px;
        .joinus-thirdly-list-right-title {
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
        }
        .joinus-thirdly-list-right-text {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 19px;
          margin-top: 2.5px;
        }
      }
    }
  }
}
</style>