<template>
  <div class="system">
    <div class="system-first">
      <nav-bar></nav-bar>
    </div>

    <div class="system-second">
      <img class="system-second-back" src="../assets/system-back.png" />
      <div class="system-second-block">
        <div class="system-second-block-title">汇智投趋势交易分析系统</div>
        <div class="system-second-block-text">TREND ANALYSIS</div>
      </div>
    </div>

    <div class="system-three">
      <div class="three-img">
        <img src="../assets/system-0.png" alt="" />
      </div>
      <div class="three-button" @click="merchandiseMet(0)">
        <div class="button-img">
          <img src="../assets/system-4.png" alt="" />
        </div>
        <div class="button-title">立即购买</div>
      </div>

      <div class="button-list" v-for="(item, index) in 3" :key="index">
        <div class="list-left">
          <img
            :src="
              index == 0
                ? require('../assets/system-1.png')
                : index == 1
                ? require('../assets/system-2.png')
                : require('../assets/system-3.png')
            "
            alt=""
          />
        </div>
        <div class="list-right">
          <div class="list-right-title">
            {{
              index == 0
                ? "汇智投趋势交易分析系统"
                : index == 1
                ? "交易模型"
                : "专业模板"
            }}
          </div>
          <div class="list-right-text">
            {{
              index == 0
                ? "《汇智投趋势交易分析系统》，是专门为自主交易的客户提供的几套趋 势型指标系统。"
                : index == 1
                ? "依托精准匹配的交易策略模型，您将能够超脱于常见的行情解读误区之外，摆脱操盘过程中沉重的心理压力。结合周密的资金配置规划与清晰的入场、离场策略，您将游刃有余地驾驭投资这场充满变数的概率之旅，最终成为自己投资命运的掌舵人。"
                : "《汇智投趋势交易分析系统》包含三套专业指标模板《DMI技术指标模板》，《MACD技术指标模板》，《WRSI技术指标模板》。"
            }}
          </div>
        </div>
      </div>
    </div>

    <div class="system-fourth">
      <div class="fourth-top">
        <div class="top-list" v-for="(item, index) in 1" :key="index">
          <img class="list-img" src="../assets/system-5.png" alt="" />
          <div class="list-title">
            {{
              index == 0 ? "汇智投三色线模板（M5）" : "汇智投三色线模板（M5）"
            }}
          </div>
        </div>
      </div>
      <div class="fourth-center">
        <div class="center-left" v-for="(item, index) in 1" :key="index">
          <div class="left-img">
            <img
              :src="
                index == 0
                  ? require('../assets/system-4.png')
                  : require('../assets/system-6.png')
              "
              alt=""
            />
          </div>

          <div class="left-title" @click="merchandiseMet(index)">
            {{ index == 0 ? "立即购买" : "软件下载" }}
          </div>
        </div>
      </div>

      <div class="fourth-bottom">
        <div class="bottom-title">
          三色线模板趋势线表现稳定，能够过滤掉较多日间杂波和假趋势，帮助趋势型自
          主交易者更好地辨识趋势，把握较大的获利点数。
        </div>

        <div class="bottom-lint"></div>

        <div class="bottom-block">
          <div class="block-title">主图：</div>
          <div class="block-text">
            1、黑屏灰屏——主图分灰色屏和黑色屏，黑屏时段波动率总体高于灰屏时段。
          </div>
          <div class="block-text">
            2、趋势线——红黄绿曲线为“趋势线”：红色表示多头趋势，绿色表示空头趋势，黄色表示趋势不明朗。
          </div>
          <div class="block-text">
            3、换K线倒计时——当前这根K线走完换下一根K线，所剩的时间。
          </div>
        </div>

        <div class="bottom-lint"></div>

        <div class="bottom-block">
          <div class="block-title">副图：</div>
          <div class="block-text">MACD——技术指标MACD。</div>
        </div>

        <div class="bottom-lint"></div>
      </div>
    </div>

    <div class="system-seventh">
      <tab-bar></tab-bar>
    </div>
  </div>
</template>

<script>
import navBar from "../components/nav-bar.vue";
import tabBar from "../components/tab-bar.vue";

import { usergoodlistGet } from "../api/index";

export default {
  components: { navBar, tabBar },
  data() {
    return {
      ///-----------
      page: 1,
      limit: 10,
      easygetObj: {},
    };
  },
  created() {
    this.usergoodlistGetMet();
  },
  mounted() {
    this.backTop();
    window.onload = function () {
      document.addEventListener("touchstart", function (e) {
        if (e.touches.length > 1) {
          e.preventDefault();
        }
      });
      document.addEventListener("gesturestart", function (e) {
        e.preventDefault();
      });
    };
  },
  methods: {
    backTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    merchandiseMet(index) {
      if (index == 0) {
        this.$router.push({
          path: "/systemParticulars",
          query: {
            id: this.easygetObj.id,
          },
        });
      } else {
        if (localStorage.getItem("info")) {
          let str =
            "https://gongafter.gjlike.com/jeecg-boot/" +
            this.easygetObj.pcUrl;
          window.open(str);
        } else {
          this.$router.push({
            path: "/register",
          });
        }
      }
    },
    usergoodlistGetMet() {
      let params = {
        pageNo: this.page,
        pageSize: this.limit,
        goodTypeId: null,
      };

      usergoodlistGet(params).then((res) => {
        if (res.code == 200) {
          this.easygetObj = res.result.records[0];
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.system {
  .system-first {
    height: 50px;
    width: 100%;
  }
  .system-second {
    width: 100%;
    position: relative;
    height: 128.5px;
    padding-top: 40px;
    box-sizing: border-box;
    .system-second-back {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: fill;
      display: flex;
      top: 0;
    }
    .system-second-block {
      height: 37px;
      position: relative;
      text-align: center;

      .system-second-block-title {
        font-size: 27.5px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 600;
        color: #ffffff;
      }
      .system-second-block-text {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        opacity: 0.3;
        letter-spacing: 1px;
      }
    }
  }

  .system-three {
    background: #fafafa;
    position: relative;
    padding: 0 16px;
    padding-bottom: 35px;
    box-sizing: border-box;

    .three-img {
      margin: 0 auto;
      width: 293.32px;
      height: 177px;

      img {
        width: 100%;
        height: 100%;
        margin-top: -20px;
      }
    }

    .three-button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      margin-bottom: 32.5px;
      width: 100%;
      height: 50px;
      background: #f8ab22;
      border-radius: 6px;

      .button-img {
        width: 21px;
        height: 21px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .button-title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 17px;
        color: #ffffff;
        margin-left: 11px;
        letter-spacing: 1px;
      }
    }

    .button-list {
      display: flex;
      margin-bottom: 22px;

      .list-left {
        img {
          width: 50px;
          height: 50px;
        }
      }

      .list-right {
        width: 82%;
        text-align: left;
        margin-left: 10px;

        .list-right-title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 15px;
          color: #333333;
          margin-bottom: 6px;
        }

        .list-right-text {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #666666;
        }
      }
    }
  }

  .system-fourth {
    padding: 32px 16px 0;
    box-sizing: border-box;
    .fourth-top {
      display: flex;
      align-items: center;
      justify-content: center;
      .top-list {
        margin-right: 18px;
        text-align: center;
        .list-img {
          width: 293.32px;
          height: 177px;
        }

        .list-title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 12px;
          color: #2481d7;
        }
      }

      .top-list:last-child {
        margin-right: 0;
      }
    }

    .fourth-center {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      margin-bottom: 32.5px;
      width: 100%;
      height: 50px;
      background: #f8ab22;
      border-radius: 6px;
      margin-top: 27px;

      .center-left {
        display: flex;
        justify-content: center;
        .left-img {
          width: 21px;
          height: 21px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .left-title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 17px;
          color: #ffffff;
          margin-left: 11px;
          letter-spacing: 1px;
        }
      }

      // .center-left,
      // .center-right {
      //   width: 150px;
      //   height: 45px;
      //   background: #f8ab22;
      //   border-radius: 5px;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;

      //   .left-img {
      //     width: 16px;
      //     height: 16px;
      //     margin-right: 5px;

      //     img {
      //       width: 100%;
      //       height: 100%;
      //     }
      //   }

      //   .left-title {
      //     font-family: PingFangSC, PingFang SC;
      //     font-weight: 500;
      //     font-size: 15px;
      //     color: #ffffff;
      //   }
      // }

      // .center-left:last-child {
      //   margin-left: 21px;
      //   background: linear-gradient(90deg, #609ce6 0%, #5084da 100%);
      // }
    }

    .fourth-bottom {
      margin-top: 22.5px;

      .bottom-title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 15px;
        text-align: center;
        color: #333333;
      }

      .bottom-lint {
        margin: 22.5px 0;
        width: 100%;
        height: 1px;
        background: #e4e4e4;
      }

      .bottom-block {
        .block-title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 15px;
          color: #2481d7;
          margin-bottom: 11px;
        }

        .block-text {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #666666;
          margin-bottom: 14px;
          line-height: 20px;
        }
      }
    }
  }

  .system-seventh {
    margin-top: 28px;
  }
}
</style>