<template>
  <div class="classroom">
    <div class="classroom-first">
      <nav-bar></nav-bar>
    </div>

    <div class="classroom-second">
      <img class="classroom-second-back" src="../assets/classroom1.png" />
      <div class="classroom-second-block">
        <div class="classroom-second-block-title">风控讲堂</div>
        <div class="classroom-second-block-text">RISK MANAGEMENT</div>
      </div>
    </div>

    <div class="classroom-thirdly">
      <div
        class="home-thirdly-block-array"
        v-for="(item, index) in 3"
        :key="index"
        @click="prevention(index)"
      >
        <div class="home-thirdly-block-array-back">
          <img
            class="home-thirdly-block-array-back-img"
            :src="
              index == 0
                ? require('../assets/pneumatic1.png')
                : index == 1
                ? require('../assets/pneumatic2.png')
                : require('../assets/pneumatic3.png')
            "
          />
          <img
            class="home-thirdly-block-array-back-play"
            src="../assets/pneumatic4.png"
          />
        </div>

        <div class="home-thirdly-block-array-block">
          <div class="home-thirdly-block-array-block-title">
            {{
              index == 0
                ? "外汇是什么？外汇基础概念讲解"
                : index == 1
                ? "外汇入门讲解"
                : "《MT4EA》5、风控EA"
            }}
          </div>
          <div class="home-thirdly-block-array-block-text">
            {{ index == 0 ? "356" : index == 1 ? "633" : "284" }}人观看
          </div>
        </div>
      </div>
    </div>

    <div class="classroom-seventh">
      <tab-bar></tab-bar>
    </div>
  </div>
</template>

<script>
import navBar from "../components/nav-bar.vue";
import tabBar from "../components/tab-bar.vue";

export default {
  components: { navBar, tabBar },
  mounted() {
    this.backTop();

    window.onload = function () {
      document.addEventListener("touchstart", function (e) {
        if (e.touches.length > 1) {
          e.preventDefault();
        }
      });
      document.addEventListener("gesturestart", function (e) {
        e.preventDefault();
      });
    };
  },
  methods: {
    // 点击对应的风控事件
    prevention(index) {
      if (index == 0) {
        window.open(
          "https://haokan.baidu.com/v?pd=wisenatural&vid=4552773370316071104"
        );
      } else if (index == 1) {
        window.open(
          "https://haokan.baidu.com/v?pd=wisenatural&vid=3489526177582554702"
        );
      } else {
        window.open("https://www.bilibili.com/video/BV1cL411b7zE");
      }
    },
    backTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.classroom {
  .classroom-first {
    height: 50px;
    width: 100%;
  }

  .classroom-second {
    width: 100%;
    position: relative;
    height: 128.5px;
    padding-top: 40px;
    box-sizing: border-box;
    .classroom-second-back {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: fill;
      display: flex;
      top: 0;
    }
    .classroom-second-block {
      height: 37px;
      position: relative;
      display: flex;
      justify-content: center;
      .classroom-second-block-title {
        font-size: 27.5px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 600;
        color: #ffffff;
      }
      .classroom-second-block-text {
        font-size: 17.5px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        position: absolute;
        bottom: -5px;
        opacity: 0.3;
        letter-spacing: 1px;
      }
    }
  }

  .classroom-thirdly {
    position: relative;
    padding-bottom: 50px;
    margin-top: 40px;
    .home-thirdly-block-array {
      height: 287px;
      background: #ffffff;
      box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.08);
      border-radius: 5.5px;
      margin: 0 24px;
      margin-bottom: 15px;

      .home-thirdly-block-array-back {
        width: 100%;
        position: relative;
        height: 210.5px;
        display: flex;
        justify-content: center;
        align-items: center;
        .home-thirdly-block-array-back-play {
          position: relative;
          width: 50px;
          height: 50px;
        }
        .home-thirdly-block-array-back-img {
          width: 100%;
          height: 100%;
          position: absolute;
          display: flex;
          object-fit: fill;
          border-radius: 5.5px 5.5px 0 0;
        }
      }

      .home-thirdly-block-array-block {
        padding: 0 21px;
        box-sizing: border-box;
        margin-top: 10.5px;
        .home-thirdly-block-array-block-title {
          font-size: 19px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 600;
          color: #333333;
          letter-spacing: 1px;
        }
        .home-thirdly-block-array-block-text {
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #b4b4b4;
          margin-top: 6px;
          letter-spacing: 1px;
        }
      }
    }

    .home-thirdly-block-array:last-child {
      margin-bottom: 0;
    }
  }
}
</style>