<template>
  <div class="order">
    <div class="order-headline">
      <div class="headline-left" @click="orderBack">
        <img src="../assets/system-14.png" alt="" />
      </div>
      <div class="headline-title">我的订单</div>
      <div class="headline-left"></div>
    </div>

    <div class="order-headline-lint"></div>

    <div class="order-tabs">
      <van-tabs
        v-model="activeIndex"
        title-active-color="#2481D7"
        color="#2481D7"
        background="#f5f5f5"
        :ellipsis="false"
        @change="activeChange"
      >
        <van-tab v-for="(item, index) in activeList" :key="index" :title="item">
        </van-tab>
      </van-tabs>
    </div>

    <div class="order-tabs-lint"></div>

    <div class="tabs-view">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text=""
        @load="onLoad"
      >
        <div
          class="view-list"
          v-for="(item, index) in orderList"
          :key="index"
          @click="tabsParticulars(item)"
        >
          <div class="list-top">
            <div class="top-left">订单id：{{ item.id }}</div>
            <div class="top-right">
              {{
                item.status == 0
                  ? "未支付"
                  : item.status == 1
                  ? "已支付"
                  : item.status == 2
                  ? "已放弃"
                  : ""
              }}
            </div>
          </div>
          <div v-for="(k, n) in item.serialNumberList" :key="n">
            <div class="list-center">
              <div class="center-left">
                <img :src="item.goodUrls" alt="" />
              </div>
              <div class="center-right">
                <div class="right-matter">
                  <div class="matter-title">{{ item.goodName }}</div>
                  <div class="matter-text">
                    有效期：
                    <span v-if="k.termId == 31">1个月</span>
                    <span v-if="k.termId == 93">3个月</span>
                    <span v-if="k.termId == 186">6个月</span>
                    <span v-if="k.termId == 366">1年</span>
                    <span v-if="k.termId == 732">2年</span>
                    <span v-if="k.termId == 1098">3年</span>
                  </div>
                  <div class="matter-text">序列号：{{ k.number }}</div>
                </div>
                <div class="right-price">
                  <div class="price-title"><span>¥</span>{{ item.price }}</div>
                  <div class="price-num">x1</div>
                </div>
              </div>
            </div>

            <div class="list-view">
              <div
                class="view-array"
                v-for="(o, j) in 2"
                :key="j"
                @click.stop="downloadStop(item, index)"
              >
                {{ j == 0 ? "商品编号" : "软件下载" }}：
                {{ j == 0 ? k.goodCode : item.goodName }}
              </div>
            </div>
          </div>

          <div class="list-bottom">
            <div class="bottom-title">总计<span>¥</span>{{ item.tprice }}</div>
            <div class="bottom-display" v-if="item.status == 0">
              <div
                class="display-list"
                v-for="(o, i) in 2"
                :key="i"
                @click.stop="functionStop(i, item)"
              >
                {{ i == 0 ? "取消订单" : "立即付款" }}
              </div>
            </div>
          </div>
        </div>

        <div class="view-empty" v-if="orderList.length == 0 && totalNum == 0">
          <van-empty
            :image="require('../assets/img_empty.png')"
            description="暂无数据"
          />
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import {
  usergoodorderGet,
  usergoodorderIdPost,
  usergoodorderIdGet,
} from "../api/index";

var showLoading = null;

export default {
  data() {
    return {
      activeIndex: 2,
      activeList: ["全部", "待支付", "已支付", "已取消"],
      //-------
      orderList: [],
      loading: false,
      finished: false,
      totalNum: -1,
      //----------
      page: 1,
      limit: 10,
    };
  },
  created() {
    this.activeIndex = Number(this.$route.query.index);
  },
  mounted() {
    this.backTop()
    window.onload = function () {
      document.addEventListener("touchstart", function (e) {
        if (e.touches.length > 1) {
          e.preventDefault();
        }
      });
      document.addEventListener("gesturestart", function (e) {
        e.preventDefault();
      });
    };
  },
  methods: {
    backTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    onLoad() {
      showLoading = this.$toast.loading({
        message: "加载中...",
      });

      let str =
        this.activeIndex == 0
          ? ""
          : this.activeIndex == 1
          ? 0
          : this.activeIndex == 2
          ? 1
          : this.activeIndex == 3
          ? 2
          : "";

      console.log("---", str);

      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        if (this.refreshing) {
          this.list = [];
          this.refreshing = false;
        }

        let params = {
          pageNo: this.page,
          pageSize: this.limit,
          status: str,
        };

        usergoodorderGet(params).then((res) => {
          if (res.code == 200) {
            res.result.records.forEach((item, index) => {
              item.pcUrl =
                "https://gongafter.gjlike.com/jeecg-boot/" + item.pcUrl;

              let list = item.goodUrl == null ? [] : item.goodUrl.split(",");
              item.goodUrls =
                "https://gongafter.gjlike.com/jeecg-boot/" + list[0];
            });
            // 获取对应的总数
            this.totalNum = res.result.total;

            this.orderList = res.result.records;
            this.loading = false;
            this.finished = true;

            if (showLoading) showLoading.close(); //请求结束关闭加载
          } else {
          }
        });
        // // 加载状态结束
      }, 100);
    },

    //---------
    activeChange(e) {
      this.activeIndex = e;

      this.$nextTick(() => {
        this.page = 1;
        this.orderList = [];
        this.onLoad();
      });
    },
    orderBack() {
      this.$router.go(-1);
    },
    downloadStop(item, index) {
      if (index == 1) {
        window.open(item.pcUrl);
      }
    },
    tabsParticulars(item) {
      this.$router.push({
        path: "/personageOrderParticulars",
        query: {
          id: item.id,
        },
      });
    },
    //--------按钮
    functionStop(index, item) {
      console.log("点击触发", index);

      if (index == 0) {
        let params = item.id;

        usergoodorderIdPost(params).then((res) => {
          if (res.code == 200) {
            this.$toast.success('取消成功！')

            this.$nextTick(() => {
              this.page = 1;
              this.orderList = [];
              this.onLoad();
            });
          }
        });
      } else {
        let price = Number(item.num) * Number(item.price);
        console.log("金额", price);

        this.$router.push({
          path: "/SystemParticularsCashier",
          query: {
            id: item.goodDetailId,
            price: price,
            num: item.num,
          },
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.order {
  min-height: 100vh;
  background: #f5f5f5;

  .order-headline {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 9;
    left: 0;
    padding: 0 15px;
    box-sizing: border-box;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f5f5f5;

    .headline-left {
      width: 25px;
      height: 25px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .headline-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 17px;
      color: #222222;
    }
  }

  .order-headline-lint {
    height: 47px;
  }

  .order-tabs {
    position: fixed;
    top: 47px;
    width: 100%;
  }

  .order-tabs-lint {
    height: 50px;
  }

  .tabs-view {
    padding: 0 10px;
    box-sizing: border-box;
    .view-list {
      padding: 15px 10px;
      box-sizing: border-box;
      border-radius: 10px;
      background: #ffffff;
      margin-bottom: 10px;

      .list-top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .top-left {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #999999;
        }
        .top-right {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #2481d7;
        }
      }

      .list-center {
        margin-top: 13px;
        display: flex;
        .center-left {
          width: 82px;
          height: 82px;
          margin-right: 10px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
            object-fit: contain;
          }
        }

        .center-right {
          width: 71%;
          display: flex;

          .right-matter {
            width: 75%;
            .matter-title {
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #222222;
              margin-bottom: 7px;
            }

            .matter-text {
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 12px;
              color: #999999;
              margin-top: 4px;
              word-break: break-all;
            }

            .matter-text:last-child {
              margin-bottom: 0;
            }
          }

          .right-price {
            width: 25%;
            text-align: right;

            .price-title {
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              font-size: 15px;
              color: #ff6a20;
              margin-bottom: 7px;
              display: flex;
              align-items: center;
              justify-content: flex-end;

              span {
                font-size: 12px;
                margin-right: 2px;
              }
            }

            .price-num {
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 12px;
              color: #999999;
              margin-top: 4px;
            }
          }
        }
      }

      .list-view {
        margin-top: 10px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .view-array {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #999999;
          word-break: break-all;
        }
      }

      .list-bottom {
        .bottom-title {
          text-align: right;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 13px;
          color: #333333;

          span {
            margin-left: 3px;
            margin-right: 2px;
          }
        }

        .bottom-display {
          margin-top: 13px;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .display-list {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 13px;
            color: #222222;
            padding: 6px 14px;
            box-sizing: border-box;
            border-radius: 17px;
            border: 1px solid #c9c9c9;
          }

          .display-list:last-child {
            margin-left: 10px;
            color: #2481d7;
            border: 1px solid #2481d7;
          }
        }
      }
    }

    .view-empty {
      /deep/ .van-empty__image {
        width: 110px;
        height: 116px;
      }
    }
  }
}
</style>