<template>
  <div class="nav">
    <div class="nav-left" @click="navtop">
      <img src="../assets/nav-logo.png" alt="" />
    </div>
    <div class="nav-right">
      <van-dropdown-menu>
        <van-dropdown-item v-model="value">
          <van-cell
            v-for="(item, index) in option"
            :key="index"
            center
            :title="item.text"
            is-link
            @click="navClick(item)"
          >
          </van-cell>
        </van-dropdown-item>
      </van-dropdown-menu>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      option: [
        { text: "首页", value: 0, url: "/" },
        { text: "产品服务", value: 1, url: "/product" },
        { text: "关于汇智启航", value: 2, url: "/craftsman" },
        { text: "资讯动态", value: 3, url: "/information" },
        { text: "风控讲堂", value: 4, url: "/classroom" },
        { text: "汇智投软件", value: 5, url: "/system" },
        { text: "加入我们", value: 6, url: "/joinus" },
        { text: "", value: 7, url: "" },
      ],
      value: "",
    };
  },
  created() {
    if (localStorage.getItem("info")) {
      this.option[7].text = "个人中心";
      this.option[7].url = "/personage";
    } else {
      this.option[7].text = "登录";
      this.option[7].url = "/register";
    }
  },
  methods: {
    // 点击跳转
    navClick(item) {
      this.$router.push({
        path: item.url,
      });
    },
    // logo跳转
    navtop() {
      this.$router.push({
        path: "/",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.nav {
  width: 100%;
  height: 50px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px 0 20px;
  box-sizing: border-box;
  position: fixed;
  z-index: 10;

  .nav-left {
    width: 100px;
    height: 25px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .nav-right {
    /deep/ .van-dropdown-menu__bar {
      height: 14.5px;
      width: 19px;
      background: url("../assets/nav-menu.png");
      padding: 0;
      background-size: 19px 14.5px;
    }

    /deep/ .van-dropdown-menu__title {
      display: none;
    }

    /deep/ .van-cell {
      padding: 0;
      height: 54.5px;
      padding: 0 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }

    /deep/ .van-dropdown-item--down {
      top: 50px !important;
    }
  }
}
</style>