<template>
  <div class="place">
    <img class="place-back" src="../assets/system-8.png" alt="" />

    <div class="place-headline">
      <div class="headline-left" @click="placeBack">
        <img src="../assets/system-9.png" alt="" />
      </div>
      <div class="headline-title">订单详情</div>
      <div class="headline-left"></div>
    </div>

    <div class="place-headline-lint "></div>

    <div class="place-block">
      <div class="block-top">
        <div class="top-left">
          <img :src="imgsList[0]" alt="" />
        </div>
        <div class="top-right">
          <div class="right-title">{{ merchandiseGoodsObj.name }}</div>
          <div class="right-display">
            <div class="display-queer">
              <span>¥</span>{{ goodssellingPrice }}
            </div>
            <div class="display-dextro">
              <div class="dextro-minus">
                <img
                  :src="
                    amountNum == 1
                      ? require('../assets/merchandise-minus-c.png')
                      : require('../assets/merchandise-minus.png')
                  "
                  alt=""
                  @click="minusAdd(0)"
                />
              </div>

              <div class="dextro-quantity">
                {{ amountNum }}
              </div>

              <div class="dextro-minus" @click="minusAdd(1)">
                <img src="../assets/merchandise-add.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="block-bottom">
        <div class="bottom-title">有效期选择</div>
        <div class="bottom-display">
          <div
            class="display-list"
            v-for="(item, index) in merchandiseGoodsList"
            :key="index"
            :class="{ validityActive: index == validityIndex }"
            @click="validityMet(index)"
          >
            <span v-if="item.termId == 31">1个月</span>
            <span v-if="item.termId == 93">3个月</span>
            <span v-if="item.termId == 186">6个月</span>
            <span v-if="item.termId == 366">1年</span>
            <span v-if="item.termId == 732">2年</span>
            <span v-if="item.termId == 1098">3年</span>
          </div>
        </div>
      </div>
    </div>

    <div class="place-button" @click="orderConfirmation">确认下单</div>
  </div>
</template>

<script>
import { usergoodtypeIdGet, usergoodlistGet, userbuyPost } from "../api/index";

export default {
  data() {
    return {
      amountNum: 1, // 数量
      //--------
      //--------------------
      indexId: "",
      merchandiseGoodsObj: {},
      imgsList: [],
      merchandiseGoodsList: [],
      //-------------
      validityIndex: 0,
      goodssellingPrice: "",
      goodsdiscountPrice: "",
      goodsdiscountObj: {},
    };
  },
  created() {
    this.indexId = this.$route.query.id;
    this.merchandiseMet();
    //-----------获取列表
    // this.usergoodlistGetMet();
  },
  mounted() {
    this.backTop()
    window.onload = function () {
      document.addEventListener("touchstart", function (e) {
        if (e.touches.length > 1) {
          e.preventDefault();
        }
      });
      document.addEventListener("gesturestart", function (e) {
        e.preventDefault();
      });
    };
  },
  methods: {
    backTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    merchandiseMet() {
      let params = this.indexId;

      usergoodtypeIdGet(params).then((res) => {
        if (res.code == 200) {
          this.merchandiseGoodsObj = res.result.good;
          let array =
            res.result.good.goodUrl == null
              ? []
              : res.result.good.goodUrl.split(",");
          let list = [];
          array.forEach((item, index) => {
            item = "https://gongafter.gjlike.com/jeecg-boot/" + item;
            list.push(item);
          });

          this.imgsList = list;

          //   this.imgs =
          //     "https://gongafter.gjlike.com/jeecg-boot/" +
          //     res.result.good.goodUrl;
          this.merchandiseGoodsList = res.result.goodDetail;
          this.validityMet(0);
        }
      });
    },

    validityMet(index) {
      this.validityIndex = index;
      this.goodssellingPrice = this.merchandiseGoodsList[index].price;
      this.goodsdiscountPrice = this.merchandiseGoodsList[index].marketPrice;
      this.goodsdiscountObj = this.merchandiseGoodsList[index];
    },
    //----------------------------------------------
    placeBack() {
      this.$router.go(-1);
    },
    minusAdd(index) {
      if (index == 0) {
        if (this.amountNum <= 1) {
          return;
        }

        this.amountNum--;
      } else {
        this.amountNum++;
      }
    },
    orderConfirmation() {
      let price = Number(this.amountNum ) * Number(this.goodssellingPrice)
      console.log('金额', price)


      this.$router.push({
        path: '/SystemParticularsCashier',
        query: {
          id: this.goodsdiscountObj.id,
          price: price,
          num: this.amountNum
        }
      })
    },
  },
};
</script>

<style lang="less" scoped>
.place {
  position: relative;
  .place-back {
    width: 100%;
    height: 322.5px;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
  }
  .place-headline {
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .headline-left {
      width: 25px;
      height: 25px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .headline-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 17px;
      color: #ffffff;
    }
  }
  .place-headline-lint {
    height: 47px;
    width: 100%;
  }
  .place-block {
    margin: 12px 12px 0;
    padding: 22px 12px;
    box-sizing: border-box;
    position: relative;
    background: #fff;
    border-radius: 10px;
    .block-top {
      display: flex;
      align-items: center;

      .top-left {
        width: 82px;
        height: 82px;

        img {
          width: 100%;
          height: 100%;
        }
      }
      .top-right {
        width: 233px;
        margin-left: 10px;
        .right-title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #222222;
        }

        .right-display {
          margin-top: 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .display-queer {
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 17px;
            color: #ff6a20;

            span {
              margin-right: 2px;
              font-size: 12px;
            }
          }

          .display-dextro {
            display: flex;
            align-items: center;

            .dextro-minus {
              width: 25px;
              height: 25px;
              cursor: pointer;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .dextro-quantity {
              margin: 0 7px;
              background: #f2f2f2;
              padding: 5px 20px;
              box-sizing: border-box;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 13px;
              color: #222222;
            }
          }
        }
      }
    }

    .block-bottom {
      margin-top: 25px;

      .bottom-title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 15px;
        color: #333333;
      }

      .bottom-display {
        display: flex;
        flex-wrap: wrap;
        .display-list {
          width: calc((100% / 3) - 10px);
          height: 29px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #666666;
          border: 1px solid #d2d2d2;
          margin-right: 11px;
          margin-top: 12px;
          border-radius: 3px;
        }

        .display-list:nth-child(3n + 3) {
          margin-right: 0;
        }

        .validityActive {
          border: 1px solid #2481d7;
          color: #2481d7;
        }
      }
    }
  }

  .place-button {
    position: fixed;
    bottom: 50px;
    height: 45px;
    background: linear-gradient(90deg, #609ce6 0%, #5084da 100%);
    border-radius: 5px;
    width: 90%;
    margin: 0 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 15px;
    color: #ffffff;
    letter-spacing: 1px;
  }
}
</style>