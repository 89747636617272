<template>
  <div class="register">
    <img class="register-back" src="../assets/register-back.png" alt="" />

    <img
      class="register-return"
      src="../assets/system-14.png"
      alt=""
      @click="backReturn"
    />

    <div class="register-first">
      <div class="first-img">
        <img src="../assets/tab-logo.png" alt="" />
      </div>

      <div class="first-title">
        欢迎登录<br />
        汇智启航科技平台
      </div>

      <div class="first-block">
        <div class="block-top">
          <div
            class="top-list"
            v-for="(item, index) in 2"
            :key="index"
            @click="tabsMet(index)"
          >
            <div
              class="list-title"
              :class="{ 'top-active': activeIndex == index }"
            >
              {{ index == 0 ? "验证码登录" : "账号密码登录" }}
            </div>

            <div class="list-lint" v-if="index == activeIndex"></div>
          </div>
        </div>
        <div class="block-center">
          <div class="center-block">
            <div class="block-list">
              <el-input
                v-model="account"
                clearable
                :placeholder="
                  activeIndex == 0 ? '请输入手机号码' : '请输入登录账号'
                "
              ></el-input>
            </div>

            <div class="block-list1" v-if="activeIndex == 0">
              <el-input
                maxlength="6"
                v-model="password"
                placeholder="请输入验证码"
              />

              <div class="list1-button" @click="verificationClick">
                {{ count == 0 ? "获取验证码" : count + "秒" }}
              </div>
            </div>

            <div class="block-list1" v-if="activeIndex == 1">
              <el-input
                v-model="password"
                placeholder="请输入登录密码"
                show-password
              ></el-input>
            </div>
          </div>
        </div>
        <div class="block-button" @click="registerMet">登 录</div>
        <div class="block-copywriter" v-if="activeIndex == 1">
          <div class="forget" @click="forgetMet">忘记密码？</div>
          <!-- <div class="enroll" @click="forgetMet(1)">还没账号？去注册</div> -->
        </div>

        <div class="block-bottom" @click="protocolCheck = !protocolCheck">
          <img
            v-if="protocolCheck == false"
            class="bottom-check"
            src="../assets/register-check.png"
            alt=""
          />
          <img
            v-if="protocolCheck == true"
            class="bottom-check"
            src="../assets/register-checkout.png"
            alt=""
          />

          <div class="bottom-title">
            我已经阅读并同意
            <span @click="dealStop(0)">《 隐私权政策 》</span>和<span
              @click="dealStop(1)"
              >《 用户协议 》</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userLogin, postcode, userRegister } from "../api/login";
import { md5 } from "../plugins/md5";

export default {
  data() {
    return {
      activeIndex: 0,
      //-------------账号密码
      account: "",
      password: "",
      protocolCheck: false,
      // 验证码
      count: 0,
      timer: null,
      //---------------登录模式 登录模式: 2 注册模式: 1
      loginType: 2,
    };
  },
  mounted() {
    this.backTop()
    window.onload = function () {
      document.addEventListener("touchstart", function (e) {
        if (e.touches.length > 1) {
          e.preventDefault();
        }
      });
      document.addEventListener("gesturestart", function (e) {
        e.preventDefault();
      });
    };
  },
  methods: {
    backTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    tabsMet(index) {
      this.account = "";
      this.password = "";
      this.activeIndex = index;
    },
    backReturn() {
      this.$router.replace("/");
      // this.$router.go(-1)
    },
    verificationClick() {
      if (this.count != 0) {
        return;
      }

      if (this.account == "") {
        this.$message.warning("请先输入手机号！");
        return;
      }

      this.postcodeMet();
    },

    postcodeMet() {
      let data = {
        phone: this.account,
        type: this.loginType,
      };

      postcode(data).then((res) => {
        if (res.message == "") {
          //倒计时时间
          const TIME_COUNT = 60;
          if (!this.timer) {
            this.count = TIME_COUNT;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.show = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
        }
      });
    },
    // 点击登录
    registerMet() {
      if (this.protocolCheck == false) {
        this.$message.warning("请先勾选协议！");

        return;
      }

      let params = {
        code: this.activeIndex == 1 ? "" : this.password,
        loginType: this.activeIndex,
        password: this.activeIndex == 0 ? "" : md5(this.password),
        phone: this.account,
      };

      userLogin(params).then((res) => {
        console.log("获取返回", res);
        if (res.code == 200) {
          // 存储登录后个人信息
          localStorage.setItem("info", JSON.stringify(res.result.userInfo));
          // 存储登录后的accessToken 登录凭证
          localStorage.setItem("token", res.result.token);

          this.$router.push({
            path: "/personage",
          });
        } else {
        }
      });
    },

    //------------忘记密码
    forgetMet() {
      this.$router.push({
        path: "/personageForget",
      });
    },
    dealStop(index) {
      if (index == 0) {
        let { href } = this.$router.resolve({
          path: "/dealPrivacy"
        });
        window.open(href, "_blank");
      } else {
        let { href } = this.$router.resolve({
          path: "/dealUser"
        });
        window.open(href, "_blank");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.register {
  height: 100vh;
  position: relative;

  .register-return {
    position: fixed;
    top: 36px;
    left: 10px;
    width: 31px;
    height: 31px;
    z-index: 10;
  }

  .register-back {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  .register-first {
    position: relative;
    padding: 0 22px;
    box-sizing: border-box;
    padding-top: 36px;
    .first-img {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      img {
        width: 135px;
        height: 34px;
      }
    }

    .first-title {
      margin-top: 23px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 20px;
      letter-spacing: 1px;
      color: #2481d7;
    }

    .first-block {
      margin-top: 46px;
      position: relative;

      .block-top {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;

        .top-list {
          position: relative;
          margin-right: 61px;
          text-align: center;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .list-title {
            font-family: PingFangSC, PingFang SC;
            font-weight: bold;
            font-size: 16px;
            color: #666666;
          }

          .top-active {
            font-weight: bold;
            color: #2481d7;
          }

          .list-lint {
            width: 53.5px;
            height: 2.5px;
            background: #2481d7;
            position: absolute;
            bottom: 0;
          }
        }

        .top-list:last-child {
          margin-right: 0;
        }
      }

      .block-center {
        margin-top: 22.5px;
        .center-block {
          .block-list,
          .block-list1 {
            margin-bottom: 15px;
            position: relative;
            display: flex;
            align-items: center;
            position: relative;
            height: 48px;
            /deep/ .el-input__inner {
              height: 48px;
              line-height: 48px;
            }

            /deep/ .el-input__inner::placeholder {
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #999999;
            }

            .list1-button {
              padding: 0 10px;
              box-sizing: border-box;
              height: 100%;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 12px;
              color: #2481d7;
              position: absolute;
              right: 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              z-index: 1;
            }
          }
        }
      }

      .block-button {
        margin-top: 25.5px;
        width: 100%;
        height: 48px;
        background: linear-gradient(90deg, #609ce6 0%, #5084da 100%);
        border-radius: 5px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 17px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .block-copywriter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #2481d7;
        cursor: pointer;
        position: absolute;
        margin-top: 20px;
      }

      .block-bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 63px;
        cursor: pointer;

        .bottom-check {
          width: 15px;
          height: 15px;
          object-fit: contain;
          margin-right: 10px;
        }

        .bottom-title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #999;

          span {
            color: #2481d7;
          }
        }
      }
    }
  }
}
</style>