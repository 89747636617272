var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"system"},[_c('div',{staticClass:"system-first"},[_c('nav-bar')],1),_vm._m(0),_c('div',{staticClass:"system-three"},[_vm._m(1),_c('div',{staticClass:"three-button",on:{"click":function($event){return _vm.merchandiseMet(0)}}},[_vm._m(2),_c('div',{staticClass:"button-title"},[_vm._v("立即购买")])]),_vm._l((3),function(item,index){return _c('div',{key:index,staticClass:"button-list"},[_c('div',{staticClass:"list-left"},[_c('img',{attrs:{"src":index == 0
              ? require('../assets/system-1.png')
              : index == 1
              ? require('../assets/system-2.png')
              : require('../assets/system-3.png'),"alt":""}})]),_c('div',{staticClass:"list-right"},[_c('div',{staticClass:"list-right-title"},[_vm._v(" "+_vm._s(index == 0 ? "汇智投趋势交易分析系统" : index == 1 ? "交易模型" : "专业模板")+" ")]),_c('div',{staticClass:"list-right-text"},[_vm._v(" "+_vm._s(index == 0 ? "《汇智投趋势交易分析系统》，是专门为自主交易的客户提供的几套趋 势型指标系统。" : index == 1 ? "依托精准匹配的交易策略模型，您将能够超脱于常见的行情解读误区之外，摆脱操盘过程中沉重的心理压力。结合周密的资金配置规划与清晰的入场、离场策略，您将游刃有余地驾驭投资这场充满变数的概率之旅，最终成为自己投资命运的掌舵人。" : "《汇智投趋势交易分析系统》包含三套专业指标模板《DMI技术指标模板》，《MACD技术指标模板》，《WRSI技术指标模板》。")+" ")])])])})],2),_c('div',{staticClass:"system-fourth"},[_c('div',{staticClass:"fourth-top"},_vm._l((1),function(item,index){return _c('div',{key:index,staticClass:"top-list"},[_c('img',{staticClass:"list-img",attrs:{"src":require("../assets/system-5.png"),"alt":""}}),_c('div',{staticClass:"list-title"},[_vm._v(" "+_vm._s(index == 0 ? "汇智投三色线模板（M5）" : "汇智投三色线模板（M5）")+" ")])])}),0),_c('div',{staticClass:"fourth-center"},_vm._l((1),function(item,index){return _c('div',{key:index,staticClass:"center-left"},[_c('div',{staticClass:"left-img"},[_c('img',{attrs:{"src":index == 0
                ? require('../assets/system-4.png')
                : require('../assets/system-6.png'),"alt":""}})]),_c('div',{staticClass:"left-title",on:{"click":function($event){return _vm.merchandiseMet(index)}}},[_vm._v(" "+_vm._s(index == 0 ? "立即购买" : "软件下载")+" ")])])}),0),_vm._m(3)]),_c('div',{staticClass:"system-seventh"},[_c('tab-bar')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"system-second"},[_c('img',{staticClass:"system-second-back",attrs:{"src":require("../assets/system-back.png")}}),_c('div',{staticClass:"system-second-block"},[_c('div',{staticClass:"system-second-block-title"},[_vm._v("汇智投趋势交易分析系统")]),_c('div',{staticClass:"system-second-block-text"},[_vm._v("TREND ANALYSIS")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"three-img"},[_c('img',{attrs:{"src":require("../assets/system-0.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-img"},[_c('img',{attrs:{"src":require("../assets/system-4.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fourth-bottom"},[_c('div',{staticClass:"bottom-title"},[_vm._v(" 三色线模板趋势线表现稳定，能够过滤掉较多日间杂波和假趋势，帮助趋势型自 主交易者更好地辨识趋势，把握较大的获利点数。 ")]),_c('div',{staticClass:"bottom-lint"}),_c('div',{staticClass:"bottom-block"},[_c('div',{staticClass:"block-title"},[_vm._v("主图：")]),_c('div',{staticClass:"block-text"},[_vm._v(" 1、黑屏灰屏——主图分灰色屏和黑色屏，黑屏时段波动率总体高于灰屏时段。 ")]),_c('div',{staticClass:"block-text"},[_vm._v(" 2、趋势线——红黄绿曲线为“趋势线”：红色表示多头趋势，绿色表示空头趋势，黄色表示趋势不明朗。 ")]),_c('div',{staticClass:"block-text"},[_vm._v(" 3、换K线倒计时——当前这根K线走完换下一根K线，所剩的时间。 ")])]),_c('div',{staticClass:"bottom-lint"}),_c('div',{staticClass:"bottom-block"},[_c('div',{staticClass:"block-title"},[_vm._v("副图：")]),_c('div',{staticClass:"block-text"},[_vm._v("MACD——技术指标MACD。")])]),_c('div',{staticClass:"bottom-lint"})])
}]

export { render, staticRenderFns }