
import { get, post, put, deletefn } from "../api/request";

// 个人信息
export const userInfoGet = params => post(`/jeecg-boot/front/user/info`, params)

// 商品分类
export const usergoodtypeGet = params => get(`/jeecg-boot/front/good/type`, params)

// 商品详情
export const usergoodtypeIdGet = params => get(`/jeecg-boot/front/good/show/${params}`)

// 我的订单
export const usergoodorderGet = params => get(`/jeecg-boot/front/good/order`, params)

// 我的订单 详情
export const usergoodorderIdGet = params => get(`/jeecg-boot/front/good/order/${params}`)

// 我的订单 取消订单
export const usergoodorderIdPost = params => post(`/jeecg-boot/front/good/order/${params}`)


// 商品列表
export const usergoodlistGet = params => get(`/jeecg-boot/front/good/list`, params)

// 下单
export const userbuyPost = params => post(`/jeecg-boot/front/order/buy`, params)

// 微信订单支付
export const userwxOrderPost = params => post(`/jeecg-boot/front/order/wx/pay`, params)

// 支付宝订单支付
export const userzfbOrderPost = params => post(`/jeecg-boot/front/order/ali/pay`, params)

// 订单查询
export const userorderQueryGet = params => get(`/jeecg-boot/front/order/show`, params)

//----------银盛第二套支付
export const userjudgeGer = params => get(`/jeecg-boot/gongqiang/mallConfiguration/list`, params)

export const userYinshengPost = params => post(`/jeecg-boot/front/yinsheng/yinsheng/pay`, params)