import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue' // 首页
import Product from '../views/product.vue' // 产品服务
import Craftsman from '../views/craftsman.vue' // 关于汇智启航
import Information from '../views/information.vue' // 资讯动态
import Classroom from '../views/classroom.vue' // 风控讲堂
import System from '../views/system.vue' // 软件
import SystemParticulars from '../views/systemParticulars.vue' // 软件详情
import SystemParticularsPlace from '../views/systemParticularsPlace.vue' // 确认下单
import SystemParticularsCashier from '../views/systemParticularsCashier.vue' // 收银台

import Joinus from '../views/joinus.vue'
//----------------------------------------------------
import Personage from '../views/personage.vue' // 个人中心
import PersonageData from '../views/personageData.vue' // 个人资料
import PersonagePhone from '../views/personagePhone.vue' // 更换手机号
import PersonageAlterPassword from '../views/personageAlterPassword.vue' // 修改密码
import PersonageForget from '../views/personageForget.vue'

import PersonageOrder from '../views/personageOrder.vue' // 我的订单
import PersonageOrderParticulars from '../views/personageOrderParticulars.vue' // 我的订单详情
//----------------------------------------------------
import dealPrivacy from '../components/deal-privacy.vue'
import dealUser from '../components/deal-user.vue'

import Register from '../components/register.vue' // 登录


Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/product',
    name: 'product',
    component: Product
  },
  {
    path: '/craftsman',
    name: 'craftsman',
    component: Craftsman
  },
  {
    path: '/information',
    name: 'information',
    component: Information
  },
  {
    path: '/classroom',
    name: 'classroom',
    component: Classroom
  },
  {
    path: '/system',
    name: 'system',
    component: System
  },
  //---------------------
  {
    path: '/dealPrivacy',
    name: 'dealPrivacy',
    component: dealPrivacy
  },
  {
    path: '/dealUser',
    name: 'dealUser',
    component: dealUser
  },

  //----------软件详情
  {
    path: '/systemParticulars',
    name: 'systemParticulars',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: SystemParticulars
  },
  {
    path: '/systemParticularsPlace',
    name: 'systemParticularsPlace',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: SystemParticularsPlace
  },
  {
    path: '/systemParticularsCashier',
    name: 'systemParticularsCashier',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: SystemParticularsCashier
  },



  //-----
  {
    path: '/joinus',
    name: 'joinus',
    component: Joinus
  },
  {
    path: '/personage',
    name: 'personage',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: Personage
  },
  {
    path: '/personageData',
    name: 'personageData',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: PersonageData
  },
  {
    path: '/personagePhone',
    name: 'personagePhone',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: PersonagePhone
  },
  {
    path: '/personageAlterPassword',
    name: 'personageAlterPassword',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: PersonageAlterPassword
  },
  {
    path: '/personageForget',
    name: 'personageForget',
    // meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: PersonageForget
  },
  {
    path: '/personageOrder',
    name: 'personageOrder',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: PersonageOrder
  },
  {
    path: '/personageOrderParticulars',
    name: 'personageOrderParticulars',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: PersonageOrderParticulars
  },



  //------------登录
  {
    path: '/register',
    name: 'register',
    component: Register
  },

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})


// 路由守卫
router.beforeEach((to, from, next) => {
  if (to.matched.some(res => res.meta.isLogin)) {//判断是否需要登录
    if (localStorage['token']) {
      next();
    } else {
      next({
        path: "/register"
      });
    }
  } else {
    next()
  }
})

export default router
