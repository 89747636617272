<template>
  <div class="craftsman">
    <div class="craftsman-first">
      <nav-bar></nav-bar>
    </div>

    <div class="craftsman-second">
      <img class="craftsman-second-back" src="../assets/craftsman1.png" />
      <div class="craftsman-second-block">
        <div class="craftsman-second-block-title">企业文化</div>
        <div class="craftsman-second-block-text">CORPORATE CULTURE</div>
      </div>
    </div>

    <div class="craftsman-thirdly">
      <img class="craftsman-thirdly-back" src="../assets/craftsman1-1.png" />
      <div class="craftsman-thirdly-block">
        <div class="craftsman-thirdly-block-title">宮匠使命</div>
        <div class="craftsman-thirdly-block-text">MISSION</div>
      </div>

      <div class="craftsman-thirdly-text">推动商业信用赋能中小企业</div>
    </div>

    <div class="craftsman-fourthly">
      <div
        class="craftsman-fourthly-list"
        v-for="(item, index) in 3"
        :key="index"
      >
        {{
          index == 0
            ? "深圳市汇智启航科技有限公司作为一家专注于金融衍生品行业一站式服务系统提供商，我们致力于为全球金融机构、投资机构及专业交易者提供全方位、定制化的解决方案，助力客户在瞬息万变的金融市场中稳健前行。"
            : index == 1
            ? "自2021年4月22日成立以来，汇智启航科技便深耕于金融衍生品技术领域，凭借卓越的技术创新能力、深厚的行业理解以及对市场动态的敏锐洞察，我们成功打造了多套高效、稳定、安全的金融衍生品系统。这些系统不仅覆盖了期货、期权、互换、远期合约等主流金融衍生品，还不断拓展至更多复杂衍生品领域，以满足市场日益增长的多元化需求。"
            : "展望未来，我们将继续秉承“专业、创新、诚信、共赢”的经营理念，不断提升自身的专业能力和服务水平，为客户提供更加优质、高效的金融衍生品服务。同时，我们也将积极拥抱金融科技的发展，推动金融衍生品行业的数字化转型和创新发展，为金融市场的繁荣稳定贡献自己的力量。"
        }}
      </div>
    </div>

    <div class="craftsman-fifth">
      <img class="craftsman-fifth-back" src="../assets/craftsman1-2.png" />
      <div class="craftsman-fifth-block">
        <div class="craftsman-fifth-block-title">
          成为金融行业最值得信赖的公司
        </div>
        <div class="craftsman-fifth-block-text">
          Become the most trusted company in the financial industry
        </div>

        <div class="craftsman-fifth-block-display">
          <div
            class="craftsman-fifth-block-display-list"
            v-for="(item, index) in 4"
            :key="index"
          >
            <div>
              <div class="craftsman-fifth-block-display-list-title">
                {{
                  index == 0
                    ? "专业"
                    : index == 1
                    ? "价值"
                    : index == 2
                    ? "开放"
                    : "绿色"
                }}
              </div>
              <div class="craftsman-fifth-block-display-list-text">
                {{
                  index == 0
                    ? "Professional"
                    : index == 1
                    ? "Valueadding"
                    : index == 2
                    ? "Open"
                    : "Green"
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="craftsman-sixth">
      <div class="craftsman-sixth-block">
        <div class="craftsman-sixth-block-title">创始团队</div>
        <div class="craftsman-sixth-block-text">FOUNDING TEAM</div>
      </div>

      <div class="craftsman-sixth-div">
        <el-carousel :interval="4000" type="card" height="200px" arrow="always">
          <el-carousel-item v-for="(item, index) in 3" :key="index">
            <img
              :src="
                index == 0
                  ? require('../assets/team1.png')
                  : index == 1
                  ? require('../assets/team2.png')
                  : require('../assets/team3.png')
              "
              alt=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>

      <div class="craftsman-sixth-block">
        <div class="craftsman-sixth-block-title">资质荣誉</div>
        <div class="craftsman-sixth-block-text">QUALIFICATION HONOR</div>
      </div>

      <div class="craftsman-sixth-array">
        <div
          class="craftsman-sixth-array-list"
          v-for="(item, index) in 8"
          :key="index"
        >
          <img
            class="craftsman-sixth-array-list-img"
            :src="
              index == 0
                ? require('../assets/enterprise1.jpg')
                : index == 1
                ? require('../assets/enterprise2.jpg')
                : index == 2
                ? require('../assets/enterprise3.jpg')
                : index == 3
                ? require('../assets/enterprise4.jpg')
                : index == 4
                ? require('../assets/enterprise5.jpg')
                : index == 5
                ? require('../assets/enterprise6.jpg')
                : index == 6
                ? require('../assets/enterprise7.jpg')
                : require('../assets/enterprise8.jpg')
            "
            alt=""
          />
          <div class="craftsman-sixth-array-list-title">
            {{
              index == 0
                ? "2022年度优秀AAA级信用企业"
                : index == 1
                ? "2022年度中国互联网行业影响力十大民族创新标杆企业"
                : index == 2
                ? "2022年度中国互联网行业影响力十大民族创新标杆企业"
                : index == 3
                ? "2022年度中国互联网行业十佳诚信连锁品牌"
                : index == 4
                ? "2022年度AAA级优秀企业"
                : index == 5
                ? "2022年度中国金融行业十佳价值品牌"
                : index == 6
                ? "2022年度AAA城镇合同守信用企业"
                : "2022年度优秀合同AAA级企业"
            }}
          </div>
        </div>
      </div>
    </div>

    <div class="craftsman-seventh">
      <tab-bar></tab-bar>
    </div>
  </div>
</template>

<script>
import navBar from "../components/nav-bar.vue";
import tabBar from "../components/tab-bar.vue";

export default {
  components: { navBar, tabBar },
  mounted() {
    this.backTop();
    window.onload = function () {
      document.addEventListener("touchstart", function (e) {
        if (e.touches.length > 1) {
          e.preventDefault();
        }
      });
      document.addEventListener("gesturestart", function (e) {
        e.preventDefault();
      });
    };
  },
  methods: {
    backTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.craftsman {
  .craftsman-first {
    height: 50px;
    width: 100%;
  }

  .craftsman-second {
    width: 100%;
    position: relative;
    height: 128.5px;
    padding-top: 40px;
    box-sizing: border-box;
    .craftsman-second-back {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: fill;
      display: flex;
      top: 0;
    }
    .craftsman-second-block {
      height: 37px;
      position: relative;
      display: flex;
      justify-content: center;
      .craftsman-second-block-title {
        font-size: 27.5px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 600;
        color: #ffffff;
      }
      .craftsman-second-block-text {
        font-size: 17.5px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        position: absolute;
        bottom: -5px;
        opacity: 0.3;
        letter-spacing: 1px;
      }
    }
  }

  .craftsman-thirdly {
    width: 100%;
    position: relative;
    height: 109px;
    padding-top: 20px;
    box-sizing: border-box;
    margin-top: 26px;
    .craftsman-thirdly-back {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: fill;
      display: flex;
      top: 0;
    }
    .craftsman-thirdly-block {
      height: 37px;
      position: relative;
      display: flex;
      justify-content: center;
      .craftsman-thirdly-block-title {
        font-size: 27.5px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 600;
        color: #ffffff;
      }
      .craftsman-thirdly-block-text {
        font-size: 17.5px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        position: absolute;
        bottom: -5px;
        opacity: 0.3;
        letter-spacing: 1px;
      }
    }

    .craftsman-thirdly-text {
      position: relative;
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      margin-top: 9.5px;
      text-align: center;
    }
  }
  .craftsman-fourthly {
    margin: 0 24px;
    margin-top: 16px;

    .craftsman-fourthly-list {
      margin-bottom: 20px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
    }

    .craftsman-fourthly-list:last-child {
      margin-bottom: 0;
    }
  }
  .craftsman-fifth {
    margin-top: 26px;
    width: 100%;
    position: relative;
    height: 200px;

    .craftsman-fifth-back {
      position: absolute;
      top: 0;
      object-fit: fill;
      width: 100%;
      display: flex;
      height: 100%;
    }

    .craftsman-fifth-block {
      position: relative;
      padding-top: 26.5px;
      box-sizing: border-box;
      .craftsman-fifth-block-title {
        font-size: 17.5px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2481d7;
        line-height: 24.5px;
        text-align: center;
      }
      .craftsman-fifth-block-text {
        font-size: 10px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        text-align: center;
        margin-top: 2px;
      }
      .craftsman-fifth-block-display {
        margin: 0 24px;
        display: flex;
        justify-content: space-between;
        margin-top: 24.5px;
        .craftsman-fifth-block-display-list {
          width: 74px;
          height: 74px;
          border: 1px solid rgba(36, 129, 215, 0.7);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          .craftsman-fifth-block-display-list-title {
            font-size: 15px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #2481d7;
            text-align: center;
          }
          .craftsman-fifth-block-display-list-text {
            font-size: 9.5px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #c9c9c9;
            text-align: center;
          }
        }
      }
    }
  }

  .craftsman-sixth {
    width: 100%;
    position: relative;
    padding-top: 20px;
    box-sizing: border-box;
    padding-bottom: 40px;
    .craftsman-sixth-block {
      height: 37px;
      position: relative;
      display: flex;
      justify-content: center;
      .craftsman-sixth-block-title {
        font-size: 27.5px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 600;
        color: #333333;
      }
      .craftsman-sixth-block-text {
        font-size: 17.5px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        position: absolute;
        bottom: -5px;
        opacity: 0.3;
        letter-spacing: 1px;
      }
    }

    .craftsman-sixth-div {
      height: 200px;
      width: 100%;
      margin: 40px 0;

      /deep/ .el-carousel__indicators--outside {
        display: none;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
    .craftsman-sixth-array {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 0 24px;
      margin-top: 26px;
      .craftsman-sixth-array-list {
        width: 155px;
        margin-bottom: 10px;

        .craftsman-sixth-array-list-img {
          width: 155px;
          height: 106px;
          border: 1px solid;
        }
        .craftsman-sixth-array-list-title {
          font-size: 12px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 16.5px;
          margin-top: 8px;
          text-align: left;
        }
      }
    }
  }
}
</style>