<template>
  <div class="cashier">
    <img class="cashier-back" src="../assets/system-8.png" alt="" />

    <div class="cashier-headline">
      <div class="headline-left" @click="cashierBack">
        <img src="../assets/system-9.png" alt="" />
      </div>
      <div class="headline-title">收银台</div>
      <div class="headline-left"></div>
    </div>

    <div class="cashier-headline-lint"></div>

    <div class="cashier-block">
      <div class="block-title">支付金额</div>
      <div class="block-price">
        <span>¥</span>
        {{ cashierPrice }}
      </div>

      <div class="block-time" v-if="result != ''">
        支付剩余时间：{{ result }}
      </div>
    </div>

    <div class="cashier-mode">
      <div class="mode-title">选择支付方式</div>
      <div class="mode-div">
        <div
          class="div-list"
          v-for="(item, index) in 2"
          :key="index"
          @click="cashierStop(index)"
        >
          <div class="list-left">
            <div class="left-img">
              <img
                :src="
                  index == 0
                    ? require('../assets/system-12.png')
                    : require('../assets/system-13.png')
                "
                alt=""
              />
            </div>
            <div class="left-title">{{ index == 0 ? "支付宝" : "微信" }}</div>
          </div>
          <div class="list-right">
            <img
              v-if="cashierPlaceIndex == index"
              src="../assets/system-11.png"
              alt=""
            />
            <img v-else src="../assets/system-10.png" alt="" />
            <!-- <img v-if="cashierCheckIndex == 0" src="../assets/system-10.png" alt="">
            <img v-if="cashierCheckIndex == 1" src="../assets/system-11.png" alt=""> -->
          </div>
        </div>
      </div>
    </div>

    <div class="cashier-qrcode">
      <div id="qrcode" ref="qrCodeUrl"></div>
    </div>

    <!-- <div class="cashier-qrcode" v-if="wxShows == 2">
      <div id="qrcode" ref="qrCodeUrl"></div>
    </div> -->

    <div class="cashier-button-lint"></div>

    <div class="cashier-button" @click="payConfirmation">确认支付</div>

    <!--弹出框-->
    <van-popup v-model="show" round :close-on-click-overlay="false">
      <div class="forget-popup">
        <img class="popup-img" src="../assets/personage-11.png" alt="" />
        <div class="popup-title">购买成功</div>
        <div class="popup-text">
          您购买软件的序列号为请在“个人中心-我的订单”可以查看到对应的序列号
        </div>
        <div class="popup-button" @click="confirmShows">确定</div>
      </div>
    </van-popup>

    <van-dialog
      v-model="supporyShow"
      title=" "
      show-cancel-button
      @confirm="openNewWindow"
    >
    <p style="text-align: center;">在线支付中</p>
    </van-dialog>
  </div>
</template>

<script>
import {
  userbuyPost,
  userwxOrderPost,
  userzfbOrderPost,
  userorderQueryGet,
  userjudgeGer,
  userInfoGet,
  userYinshengPost,
} from "../api/index";

var showLoading = null;

// 二维码组件
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      show: false,
      //------------
      cashierPlaceIndex: 0,
      wxShows: 0,
      //------------倒计时
      purchaseTimer: undefined, //定时器
      result: "", //倒计时显示
      //----------------------
      cashierPrice: "",
      cashierId: "",
      cashierNum: 1,
      //---------------------------------------------开始下单
      // 二维码
      qrcode: null,
      aggregatepaymentOrder: "",
      aggregatepaymentOrderNum: "",
      //-----------支付宝
      AlipayCode: "",
      //------------------是否银盛
      is_yingsheng: 0,
      supporyShow: false,
      supporyShowTitle: "",
    };
  },
  created() {
    this.cashierId = this.$route.query.id;
    this.cashierNum = this.$route.query.num;
    this.cashierPrice = this.$route.query.price;
    clearInterval(this.purchaseTimer);
    clearInterval(this.timers);

    this.userjudgeGerMet();
  },
  mounted() {
    this.backTop();
    window.onload = function () {
      document.addEventListener("touchstart", function (e) {
        if (e.touches.length > 1) {
          e.preventDefault();
        }
      });
      document.addEventListener("gesturestart", function (e) {
        e.preventDefault();
      });
    };
  },
  beforeDestroy() {
    //页面销毁清除定时器
    clearInterval(this.purchaseTimer);
    clearInterval(this.timers);
  },
  methods: {
    userjudgeGerMet() {
      let params = {};

      userjudgeGer(params).then((res) => {
        if (res.code == 200) {
          this.is_yingsheng = res.result.records[1].mvalue;
        }
      });
    },
    //-----------------------------
    backTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    //获取当前时间的24小时倒计时
    countDown(orderTime, countTime) {
      let now = new Date(); // 获取当前时间
      const countdownTime = countTime - (now - orderTime); //剩余的时间，越来越少

      let h = parseInt((countdownTime / 1000 / 60 / 60) % 24);
      let m = parseInt((countdownTime / 1000 / 60) % 60);
      let s = Math.floor((countdownTime / 1000) % 60);

      h = h > 9 ? h : "0" + h;
      m = m > 9 ? m : "0" + m;
      s = s > 9 ? s : "0" + s;
      this.result = h + "时" + m + "分" + s + "秒";
      return this.result;
    },
    startTime() {
      let order_time = new Date(); //订单时间
      let count_time = 24 * 60 * 60 * 1000; //倒计时24小时毫秒数;
      // 循环执行
      this.purchaseTimer = setInterval(() => {
        this.countDown(order_time, count_time);
      }, 1000);
    },
    //-----------------------------
    cashierBack() {
      clearInterval(this.purchaseTimer);
      clearInterval(this.timers);
      this.$router.go(-1);
    },
    cashierStop(index) {
      // 0 支付宝  1 微信
      this.cashierPlaceIndex = index;
    },
    //------------------------------------------------------------------支付流程
    // 订单生成
    payConfirmation() {
      showLoading = this.$toast.loading({
        message: "订单生成中",
      });

      let params = {
        goodDetailId: this.cashierId,
        num: this.cashierNum,
      };

      userbuyPost(params).then((res) => {
        if (res.code == 200) {
          clearInterval(this.purchaseTimer);
          clearInterval(this.timers);
          this.aggregatepaymentOrder = res.result.id;
          this.aggregatepaymentOrderNum = res.result.orderNum;

          if (this.is_yingsheng == 1) {
            // 银盛
            if (this.cashierPlaceIndex == 1) {
              this.materialMet();
            } else {
              this.userYinshengPostMet();
            }
          } else {
            this.userYinshengPostMet();
          }

          // if (this.cashierPlaceIndex == 0) {
          //   this.wxShows = 0;
          //   this.userzfbOrderPostMet();
          //   // 支付宝
          // } else {
          //   this.wxShows = 1;
          //   this.userwxOrderPostMet();
          // }
        }
      });
    },

    // 循环监听订单是否完成
    userorderQueryGetMet(msga) {
      //判断支付是否成功，监听微信支付是否成功
      let _this = this;
      let num = 0;
      _this.timers = setInterval(() => {
        num++;
        let params = {
          orderId: this.aggregatepaymentOrder, //订单号
        };
        userorderQueryGet(params).then((res) => {
          if (res.code == 200) {
            if (res.result == 0) {
              return;
            } else if (res.result == 1) {
              clearInterval(this.timers);
              this.show = true;
            } else if (res.result == 2) {
              this.$message.warning("订单已超时");
            }
          }
        });
        if (num == 500) {
          (this.ispay = false),
            //别忘了清除定时器
            clearInterval(this.timers);
        }
      }, 1500);
    },
    //------------------------------------------------------支付区域
    // 银盛-微信
    materialMet() {
      let params = {};

      userInfoGet(params).then((res) => {
        if (res.code == 200) {
          this.wxShows = 1;
          this.$refs.qrCodeUrl.innerHTML = "";

          let url = `https://pay.gjlike.com/#/?userhome_id=${res.result.id}&totalFee=${this.cashierPrice}&orderNumber=${this.aggregatepaymentOrder}&token=${res.result.accessToken}`;

          let long = document.documentElement.clientWidth * (180 / 375);
          this.long = long;

          //wx二维码显示
          this.$nextTick(() => {
            var qrcode = new QRCode(this.$refs.qrCodeUrl, {
              text: url,
              width: long,
              height: long,
              colorDark: "#000000",
              colorLight: "#ffffff",
              correctLevel: QRCode.CorrectLevel.H,
            });
          });

          if (showLoading) showLoading.close(); //请求结束关闭加载

          let _this = this;
          _this.userorderQueryGetMet();
        }
      });
    },

    // 银盛-支付宝
    //-------------银盛支付宝  第二套
    userYinshengPostMet() {
      let params = {
        orderId: this.aggregatepaymentOrder,
        payType: 0,
        platform:
          this.is_yingsheng == 0
            ? this.cashierPlaceIndex == 1
              ? "tengxu"
              : "alibaba"
            : "Yinsheng",
        way: "scan",
        channel:
          this.is_yingsheng == 1
            ? this.cashierPlaceIndex == 1
              ? "wechat"
              : "alipay"
            : "",
      };

      userYinshengPost(params).then((res) => {
        if (res.code == 200) {
          this.$refs.qrCodeUrl.innerHTML = "";
          let long = document.documentElement.clientWidth * (180 / 375);
          this.long = long;

          let strLog = "";
          if (this.is_yingsheng == 0) {
            if (this.cashierPlaceIndex == 0) {
              const div = document.createElement("div");
              div.id = "alipay";
              div.innerHTML = res.result;
              document.body.appendChild(div);
              document.querySelector("#alipay").children[0].submit(); // 执行后会唤起支付宝
            } else {
              strLog = res.result;
            }
          } else {
            this.supporyShowTitle = res.result.qrCode;
            this.supporyShow = true;
            // const div = document.createElement("div");
            // div.id = "alipay";
            // div.innerHTML = res.result.qrCode;
            // document.body.appendChild(div);
            // document.querySelector("#alipay").children[0].submit(); // 执行后会唤起支付宝
            // window.open(res.result.qrCode, '_brank')

            // strLog = res.result.qrCode;
          }

          if (strLog != "") {
            //wx二维码显示
            this.$nextTick(() => {
              var qrcode = new QRCode(this.$refs.qrCodeUrl, {
                text: strLog,
                width: long,
                height: long,
                colorDark: "#000000",
                colorLight: "#ffffff",
                correctLevel: QRCode.CorrectLevel.H,
              });
            });

            if (showLoading) showLoading.close(); //请求结束关闭加载
          }

          let _this = this;
          _this.userorderQueryGetMet();
        }
      });
    },

    //------------------------------------------------------------------- 普通
    // 微信二维码生成
    userwxOrderPostMet() {
      let params = {
        orderId: this.aggregatepaymentOrder,
      };

      userwxOrderPost(params).then((res) => {
        if (res.code == 200) {
          this.startTime(); //调用定时器
          this.$refs.qrCodeUrl.innerHTML = "";
          //购买成功
          let long = document.documentElement.clientWidth * (180 / 375);
          this.long = long;

          //wx二维码显示
          this.$nextTick(() => {
            var qrcode = new QRCode(this.$refs.qrCodeUrl, {
              text: res.result.codeUrl,
              width: long,
              height: long,
              colorDark: "#000000",
              colorLight: "#ffffff",
              correctLevel: QRCode.CorrectLevel.H,
            });
          });

          if (showLoading) showLoading.close(); //请求结束关闭加载

          let _this = this;
          _this.userorderQueryGetMet();
        }
      });
    },

    //----支付宝二维码生成
    userzfbOrderPostMet() {
      let params = {
        orderId: this.aggregatepaymentOrder,
      };

      userzfbOrderPost(params).then((res) => {
        if (res.code == 200) {
          this.startTime(); //调用定时器
          // this.AlipayCode = res.result;
          const div = document.createElement("div");
          div.id = "alipay";
          div.innerHTML = res.result;
          document.body.appendChild(div);
          document.querySelector("#alipay").children[0].submit(); // 执行后会唤起支付宝

          let _this = this;
          _this.userorderQueryGetMet();
        }
      });
    },
    openNewWindow(event) {
      // 使用原生 DOM 事件触发器来避免安全拦截
      const link = document.createElement("a");
      link.target = "_blank";
      link.href = this.supporyShowTitle;
      // 使用 event 参数来确保这不是通过 Vue 事件系统触发的
      link.dispatchEvent(
        new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: false,
        })
      );
    },
    //---------------------------------

    confirmShows() {
      this.show = false;
      this.$router.replace({
        path: "/personageOrder",
        query: {
          index: 0,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.cashier {
  min-height: 100vh;
  background: #f5f5f5;

  position: relative;
  .cashier-back {
    width: 100%;
    height: 322.5px;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
  }

  .cashier-headline {
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .headline-left {
      width: 25px;
      height: 25px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .headline-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 17px;
      color: #ffffff;
    }
  }

  .cashier-headline-lint {
    height: 47px;
    width: 100%;
  }

  .cashier-block {
    margin: 12px 12px 0;
    padding: 22px 12px;
    box-sizing: border-box;
    position: relative;
    background: #fff;
    border-radius: 10px;

    .block-title {
      text-align: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 15px;
      color: #333333;
    }

    .block-price {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 33px;
      color: #ff6737;
      margin: 10px 0;

      span {
        font-size: 20px;
        margin-right: 10px;
      }
    }

    .block-time {
      text-align: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 13px;
      color: #666666;
    }
  }

  .cashier-mode {
    margin: 12px 12px 0;
    padding: 17px 12px 0;
    box-sizing: border-box;
    position: relative;
    background: #fff;
    border-radius: 10px;

    .mode-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 15px;
      color: #333333;
    }

    .mode-div {
      .div-list {
        height: 60px;
        align-items: center;
        display: flex;
        justify-content: space-between;
        .list-left {
          display: flex;
          align-items: center;

          .left-img {
            width: 21px;
            height: 21px;
            margin-right: 10px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .left-title {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 15px;
            color: #222222;
          }
        }

        .list-right {
          width: 20px;
          height: 20px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .div-list:first-child {
        border-bottom: 1px solid #ececed;
      }
    }
  }

  .cashier-qrcode {
    margin: 20px auto;
    width: 220px;
    height: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cashier-button-lint {
  }

  .cashier-button {
    margin-top: 20px;
    height: 45px;
    background: linear-gradient(90deg, #609ce6 0%, #5084da 100%);
    border-radius: 5px;
    width: 90%;
    margin: 0 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 15px;
    color: #ffffff;
    letter-spacing: 1px;
    margin-bottom: 20px;
  }

  //------------
  .forget-popup {
    width: 259px;
    padding: 20px 0;
    box-sizing: border-box;
    background: #ffffff;
    text-align: center;

    .popup-img {
      width: 40px;
      height: 40px;
    }

    .popup-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 17px;
      color: #333333;
      margin: 15px 0 12px;
    }

    .popup-text {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #666666;
    }

    .popup-button {
      width: 136px;
      height: 35px;
      background: #2481d7;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 15px;
      color: #ffffff;
      margin: 21px auto 0;
    }
  }
}
</style>