<template>
  <div id="app">
    <div class="download-layer" v-show="showTip == true">
      <img
        class="download-layer-img"
        src="./assets/Montmorillonite.png"
        alt=""
        srcset=""
      />
    </div>

    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 下载蒙层
      showTip: false,
    };
  },
  created() {
    this.APPshow();
  },
  mounted() {
    window.onload = function () {
      document.addEventListener("touchstart", function (e) {
        if (e.touches.length > 1) {
          e.preventDefault();
        }
      });
      document.addEventListener("gesturestart", function (e) {
        e.preventDefault();
      });
    };
  },
  methods: {
    APPshow() {
      var ua = navigator.userAgent.toLowerCase();
      console.log(navigator.userAgent.toLowerCase());

      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        this.showTip = true;
      } else if (ua.indexOf(" qq") > -1 && ua.indexOf("mqqbrowser") > 0) {
        //qq内置浏览器
        this.showTip = true;
      } else {
        this.showTip = false;
      }
    },
  },
};
</script>

<style lang="less">
body {
  margin: 0;
  padding: 0;
}

.download-layer {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  .download-layer-img {
    width: 100%;
    height: 100%;
    z-index: 10;
    left: 0;
    top: 0;
  }
}
</style>
