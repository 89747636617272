<template>
  <div class="home">
    <div class="home-first">
      <nav-bar></nav-bar>
    </div>

    <div class="home-second">
      <van-swipe class="my-swipe" :autoplay="300000" indicator-color="#fff">
        <van-swipe-item v-for="(item, index) in 5" :key="index">
          <div class="home-second-swiper1" v-if="index == 0">
            <img class="swiper1-img" src="../assets/home-swiper-1.png" alt="" />
            <div class="swiper1-block">
              <div class="swiper1-block-title">
                金融衍生品行业一站式服务系统提供商
              </div>
              <div class="swiper1-block-text">专业、创新、诚信、品优</div>

              <div
                class="swiper1-block-list"
                v-for="(item, index) in 3"
                :key="index"
              >
                <div class="swiper1-block-list-num">
                  {{ index == 0 ? "32,584" : index == 1 ? "666" : "100" }}

                  <span>+</span>
                </div>
                <div class="swiper1-block-list-text">
                  {{
                    index == 0
                      ? "服务会员数量"
                      : index == 1
                      ? "服务核心企业"
                      : "金融衍生产品数量"
                  }}
                </div>
              </div>
            </div>
          </div>

          <div class="home-second-swiper1" v-if="index == 1">
            <img
              class="swiper1-img"
              src="../assets/home-swiper-1-2.png"
              alt=""
            />
            <div class="swiper1-block">
              <div class="swiper1-block-display">
                <div
                  class="swiper1-block-display-array"
                  v-for="(item, index) in 4"
                  :key="index"
                >
                  <div class="swiper1-block-display-array-title">
                    {{
                      index == 0
                        ? "标准AI产品"
                        : index == 1
                        ? "定制风控服务"
                        : index == 2
                        ? "数字化转型赋能"
                        : "小微解决方案"
                    }}
                  </div>
                  <div class="swiper1-block-display-array-img">
                    <img
                      :src="
                        index == 0
                          ? require('../assets/home-swiper-1-2-icon_1.png')
                          : index == 1
                          ? require('../assets/home-swiper-1-2-icon_2.png')
                          : index == 2
                          ? require('../assets/home-swiper-1-2-icon_3.png')
                          : require('../assets/home-swiper-1-2-icon_4.png')
                      "
                    />
                  </div>
                  <div class="swiper1-block-display-array-text">
                    {{
                      index == 0
                        ? "基于大数据技术与多年AI建模经验打造多款模型，覆盖信贷业务全部流程。"
                        : index == 1
                        ? "为金融机构提供定制化精准获客、精准风控及精准运营服务。"
                        : index == 2
                        ? "通过化精准获客、智能风控平台及咨询推动金融机构数字化转系。"
                        : "通过风险评分、场景化产品等咨建设金融机构小微业务能力。 "
                    }}
                  </div>
                </div>
              </div>

              <div class="swiper1-block-watch">
                <div
                  class="swiper1-block-watch-array"
                  v-for="(item, index) in 5"
                  :key="index"
                >
                  <img
                    class="swiper1-block-watch-array-img"
                    :src="
                      index == 0
                        ? require('../assets/home-swiper-1-2-icon_nongye.png')
                        : index == 1
                        ? require('../assets/home-swiper-1-2-icon_xiaofei.png')
                        : index == 2
                        ? require('../assets/home-swiper-1-2-icon_hujing.png')
                        : index == 3
                        ? require('../assets/home-swiper-1-2-icon_zichan.png')
                        : require('../assets/home-swiper-1-2-icon_xintuo.png')
                    "
                    alt=""
                  />
                  <div class="swiper1-block-watch-array-title">
                    {{
                      index == 0
                        ? "农业银行"
                        : index == 1
                        ? "消费金融"
                        : index == 2
                        ? "互金平台"
                        : index == 3
                        ? "资产管理"
                        : "信托公司"
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="home-second-swiper1" v-if="index == 2">
            <img
              class="swiper1-img"
              src="../assets/home-swiper-1-3.png"
              alt=""
            />
            <div class="swiper1-block">
              <div class="swiper1-block-advantage">
                <div class="swiper1-block-advantage-title">汇智启航优势</div>
                <div class="swiper1-block-advantage-text">
                  GONGJIANG ADVANTAGE
                </div>
              </div>

              <div class="swiper1-block-interval">
                汇智启航为近200家银行、消费金融公司、互联网平台等持牌机构
                提供数字化转型升级服务，并与持牌征信机构、合规大数据公
                司和IT系统服务商等建立紧密合作，深度赋能整个金融科技产 业生态。
              </div>

              <div class="swiper1-block-advantagelist">
                <div
                  class="swiper1-block-advantagelist-array"
                  v-for="(item, index) in 4"
                  :key="index"
                >
                  <img
                    class="swiper1-block-advantagelist-array-img"
                    :src="
                      index == 0
                        ? require('../assets/home-swiper-1-3-1_icon.png')
                        : index == 1
                        ? require('../assets/home-swiper-1-3-2_icon.png')
                        : index == 2
                        ? require('../assets/home-swiper-1-3-3_icon.png')
                        : require('../assets/home-swiper-1-3-4_icon.png')
                    "
                    alt=""
                  />
                  <div class="swiper1-block-advantagelist-array-title">
                    {{
                      index == 0
                        ? "甲方风控基因"
                        : index == 1
                        ? "合规引领市场"
                        : index == 2
                        ? "闭环服务能力"
                        : "白盒服务方式"
                    }}
                  </div>
                  <div class="swiper1-block-advantagelist-array-text">
                    {{
                      index == 0
                        ? "完备的甲方风控实战经验聚焦业务实质的服务理念"
                        : index == 1
                        ? "坚持甲方标准做乙方业务以合规模式服务持牌机构"
                        : index == 2
                        ? "端到端辅助运营体系建设模块化解决方案能力输出"
                        : "产品服务全流程开放透明与客户分享实战经验沉淀"
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="home-second-swiper1" v-if="index == 3">
            <img
              class="swiper1-img"
              src="../assets/home-swiper-1-4.png"
              alt=""
            />
            <div class="swiper1-block">
              <div class="swiper1-block-advantage">
                <div class="swiper1-block-advantage-title">数据中心服务</div>
                <div class="swiper1-block-advantage-text">
                  DATA CENTER SERVICES
                </div>
              </div>

              <div class="swiper1-block-div">
                <div class="swiper1-block-div-text">面向未来的新T基础架构</div>
                <div class="swiper1-block-div-text">
                  一体化、智能化、可视化运维管理平台
                </div>
                <div class="swiper1-block-div-text">
                  运、营、管、控全方位一站式管理服务
                </div>
              </div>

              <div class="swiper1-block-table">
                <div
                  class="swiper1-block-table-array"
                  v-for="(item, index) in 3"
                  :key="index"
                >
                  <div class="swiper1-block-table-array-left">
                    <img
                      :src="
                        index == 0
                          ? require('../assets/home-swiper-1-4-icon_1.png')
                          : index == 1
                          ? require('../assets/home-swiper-1-4-icon_2.png')
                          : require('../assets/home-swiper-1-4-icon_3.png')
                      "
                      alt=""
                    />
                  </div>
                  <div class="swiper1-block-table-array-right">
                    <div class="swiper1-block-table-array-right-title">
                      {{
                        index == 0
                          ? "托管服务"
                          : index == 1
                          ? "管理服务"
                          : "增值服务"
                      }}
                    </div>
                    <div class="swiper1-block-table-array-right-text">
                      {{
                        index == 0
                          ? "高度安全、可靠和容错的数据中心环境提供机柜租赁、场地租赁、BCP席位等"
                          : index == 1
                          ? "7*24小时托管运维服务，业务连续性灾备解决方案，系统、网络、数据、中间件等一站式管理服务，覆盖全IT生命周期"
                          : "专业业务咨询、金融行业解决方案，专属定制服务为金融客户订制专属一站式服务菜单"
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="home-second-swiper1" v-if="index == 4">
            <img
              class="swiper1-img"
              src="../assets/home-swiper-1-5.png"
              alt=""
            />
            <div class="swiper1-block">
              <div class="swiper1-block-advantage">
                <div
                  class="swiper1-block-advantage-title"
                  style="color: #2481d7"
                >
                  客户第一 服务为先
                </div>
                <div
                  class="swiper1-block-advantage-text"
                  style="color: #2481d7"
                >
                  CUSTOMERFIRST SERVICE FIRST
                </div>
              </div>

              <div
                class="swiper1-block-interval"
                style="
                  text-align: center;
                  font-size: 12px;
                  font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: 600;
                  color: #333333;
                "
              >
                简单温暖，专业高效 —— 价值观
              </div>

              <div class="swiper1-block-location">
                <div
                  class="swiper1-block-location-array"
                  v-for="(item, index) in 2"
                  :key="index"
                >
                  <div class="swiper1-block-location-array-title">
                    {{
                      index == 0 ? "不忘初心，简单坚守" : "创新图变，奋楫争先"
                    }}
                  </div>
                  <div class="swiper1-block-location-array-text">
                    {{
                      index == 0
                        ? "汇智启航科技自诞生之日起。经过多年运营，汇智启航科技的业务模式已获得包括行业专家、律师事务所、金融机构、监管机构、行业协会及超过百家上市公司的认可与好评，树立了正直的企业形象。"
                        : "富匠科技坚持供应链金融科技创新，重磅推出多项全国首创金融科技服务，包括首张应收账款电子债权凭证、首张供应链票据、首次将区块链与凭证业务结合、首单不特定原始权益人ABS储架发行等。汇智启航科技多次实现“从0到1”的突破，始终走在供应链金融科技前沿。"
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>

    <div class="home-thirdly">
      <div class="home-thirdly-tab">
        <van-tabs v-model="active" swipeable>
          <van-tab v-for="(item, index) in 5" :key="index">
            <template #title>
              <img
                class="home-thirdly-tab-img"
                :src="
                  index == 0
                    ? require('../assets/home-swiper-2-1.png')
                    : index == 1
                    ? require('../assets/home-swiper-2-2.png')
                    : index == 2
                    ? require('../assets/home-swiper-2-3.png')
                    : index == 3
                    ? require('../assets/home-swiper-2-4.png')
                    : require('../assets/home-swiper-2-5.png')
                "
                alt=""
              />
            </template>

            <div class="home-thirdly-tab-center" v-if="index == 0">
              <div
                class="home-thirdly-tab-center-list"
                v-for="(item, index) in 4"
                :key="index"
              >
                <div class="home-thirdly-tab-center-list-title">
                  {{
                    index == 0
                      ? "标准Al产品"
                      : index == 1
                      ? "风险类评分"
                      : index == 2
                      ? "联合建模服务"
                      : "KYC系列评分"
                  }}
                </div>
                <div class="home-thirdly-tab-center-list-text">
                  {{
                    index == 0
                      ? "团队拥有多年实战积累的业务经验，基于金融业务各类场景需求对大数据进行深度挖掘，结合先进的人工智能技术，打造出多款风险画像和模型产品，可覆盖金融机构业务全流程。"
                      : index == 1
                      ? "欺诈风险评分、信用风险评分、多头借贷风险评分等风险系列产品，可有效帮助金融机构精准识别高风险人群，提高风控审批效率，降低坏账率，提升综合风控能力。也可用于贷中风险预警，提前察觉客户风险变化，有效避免损失。"
                      : index == 2
                      ? "汇智启航科提供多种方式的联合建模服务，可以更有针对性的为客户提供在特定场景、客群下的模型定制服务。基于我们对各类模型特征的深刻理解与洞察，以及对机器学习算法的应用，能够极大提升联合建模效率，做到事半功倍。"
                      : "客户价值评估指数、资产评估指数等KYC系列产品，从多维度描述用户行为画像，综合判断个人财富累积值。可评估用户价值，应用于精准营销、客群分层、额度策略、休眠客户激活等场景，帮助业务实现精细化运营。"
                  }}
                </div>
              </div>
            </div>

            <div class="home-thirdly-tab-center" v-if="index == 1">
              <div
                class="home-thirdly-tab-center-list"
                v-for="(item, index) in 4"
                :key="index"
              >
                <div class="home-thirdly-tab-center-list-title">
                  {{
                    index == 0
                      ? "定制风控服务"
                      : index == 1
                      ? "定制模型"
                      : index == 2
                      ? "定制策略"
                      : "资金资产"
                  }}
                </div>
                <div class="home-thirdly-tab-center-list-text">
                  {{
                    index == 0
                      ? "定制风控服务包括营销响应模型、拒件捞回、定制建模、零售信贷业务赋能、额度策略、A卡模型验证与优化等，为金融机构提供差异化、定制化，覆盖个人消费信贷业务全流程服务的整体解决方案。"
                      : index == 1
                      ? "基于领先的建模能力和对复杂AI算法的精准驾驭，可根据实际业务和场景，为合作机构提供从样本标签提取、底层变量挖掘、模型算法优选、模型开发验证到应用指标监控的全流程、体系化的定制化场景模型开发，覆盖贷前、贷中、贷后等信贷全流程各个环节，如反欺诈、风险评估、额度定价、人行征信、营销获客、A卡模型优化、信用卡高风险套现模型等，助力机构实现精细化风控运营。汇智启航的定制建模解决方案周期短、模式新、上线快，基本4-8周可完成模型上线，目前已被多家合作机构长时间线上使用，效果已经过理论和实践的双重验证。"
                      : index == 2
                      ? "风控策略是信贷业务中非常重要的一环，监管层面、机构层面、风控层面均需遵循一定的原则，无论是业务冷启动还是发展阶段，均对策略的有效性、稳定性有非常严格的要求。汇智启航拥有丰富风控体系从0到1的搭建经验，可以为金融机构提供有针对性的，适用于各场景的规则及规则集的制定、策略执行顺序分析及优化等服务，同时协助机构完成不同渠道风控策略的定制化，助力金融机构实现自主风控，提高风险把控力。"
                      : "为机构提供行业风险趋势分析、多资产分析等业务调研方案，定制化资产收益于组合建议、流量接入评估与规划等，解决金融机构对信贷资产的风险和收益兼顾难题，助力金融机构进行信贷资产组合管理，实现持续收益。"
                  }}
                </div>
              </div>
            </div>

            <div class="home-thirdly-tab-center" v-if="index == 2">
              <div
                class="home-thirdly-tab-center-list"
                v-for="(item, index) in 4"
                :key="index"
              >
                <div class="home-thirdly-tab-center-list-title">
                  {{
                    index == 0
                      ? "数字化转型赋能"
                      : index == 1
                      ? "智慧精准营销-流量获客"
                      : index == 2
                      ? "智能风控平台"
                      : "汇智启航咨询"
                  }}
                </div>
                <div class="home-thirdly-tab-center-list-text">
                  {{
                    index == 0
                      ? "定制风控服务包括营销响应模型、拒件捞回、定制建模、零售信贷业务赋能、额度策略、A卡模型验证与优化等，为金融机构提供差异化、定制化。覆盖个人消费信贷业务全流程服务的整体解决方案。"
                      : index == 1
                      ? "借助大数据技术和用户特征捕捉能力，通过精准客群分类、海量数据画像挖掘、模型流程设计，产出意愿模型，结合融慧风险预筛选逻辑，共同形成白名单产品，并将白名单应用到获客领域，可有效兼顾意愿与风险，为客户匹配腾讯系、头条系等丰富优质的流量资源并进行精准投放，助力金融机构提升获客效果。"
                      : index == 2
                      ? "经过多年的技术打磨，融慧以人工智能，大数据技术为基础，以关联网络、决策引擎、机器学习、图算法等技术为核心打造了征信指标管理平台、资产质量监控平台、模型监控管理平台、数据管理平台等一系列信贷风控系统类产品，形成了独具汇智启航特色的智能风控中台，为金融机构的全流程、全周期业务风控决策提供辅助支持，帮助金融机构实现降本增效。"
                      : "汇智启航咨询拥有一支高学历、高素质的专家顾问团队，成员均毕业于密歇根州立大学、北京大学、清华大学、UCL等海内外名校，且曾任职于美国运通、PWC、德勤、百度金融、宜信等知名企业，具备多年信用卡、消费金融等领域的风控实战经验。汇智启航的咨询团队可为金融机构提供闭环能力驱动的全方位咨询及落地服务。"
                  }}
                </div>
              </div>
            </div>

            <div class="home-thirdly-tab-center" v-if="index == 3">
              <div class="home-thirdly-tab-center-title">小微综合解决方案</div>
              <div class="home-thirdly-tab-center-text">中小微行业现状</div>

              <div
                class="home-thirdly-tab-center-array"
                v-for="(item, index) in 3"
                :key="index"
              >
                <div class="home-thirdly-tab-center-array-title">
                  {{
                    index == 0
                      ? "1.企业信息收集难"
                      : index == 1
                      ? "2.风险难控制"
                      : "3.数据接入频繁"
                  }}
                </div>
                <div class="home-thirdly-tab-center-array-text">
                  {{
                    index == 0
                      ? "企业发票和纳税数据收集繁琐，容易错失，贷前筛查，贷后监控难以周全。传统尽调方式是由人工完成，效率低，成本高。"
                      : index == 1
                      ? "人工操作中可能存在的道德风险和欺诈风险:贷后监管精准度低，不良率，坏账率无法有效控制。"
                      : "需要接入多发数据源，技术重复对接，数据清洗，加工处理工作繁重。"
                  }}
                </div>
              </div>

              <div class="home-thirdly-tab-center-div">
                <div
                  class="home-thirdly-tab-center-div-span"
                  v-for="(item, index) in 3"
                  :key="index"
                >
                  {{
                    index == 0
                      ? "汇智启航致力于整合自身现有的资源与能力，为汇智启航机构小微业务场景。"
                      : index == 1
                      ? "提供基础信息核验、风险模型评分、跨境电商等场景化产品、风控整体性产品及服务解决方案。"
                      : " 大力支持汇智启航机构小微业务能力建设，确保业务可持续化稳健发展。"
                  }}
                </div>
              </div>
            </div>

            <div class="home-thirdly-tab-center" v-if="index == 4">
              <div class="home-thirdly-tab-center-title">EA优势</div>

              <div
                class="home-thirdly-tab-center-array"
                style="display: flex; justify-content: space-between"
                v-for="(item, index) in 7"
                :key="index"
              >
                <div class="home-thirdly-tab-center-array-left">
                  <div class="home-thirdly-tab-center-array-left-num">
                    {{ index + 1 }}
                  </div>
                  <div class="home-thirdly-tab-center-array-left-lint"></div>
                </div>
                <div class="home-thirdly-tab-center-array-right">
                  {{
                    index == 0
                      ? "融合盈利系统的交易精华，把经过验证的交 易策略运用于实盘交易"
                      : index == 1
                      ? "克服人性中的弱点，避免情绪化操作"
                      : index == 2
                      ? "由电脑自动下单，自动适应价格变动和趋势 变动，可以更敏感 快速地响应价格变动和 趋势变化，保证更快的下单。"
                      : index == 3
                      ? "电脑24小时监控行情，无须人工操作，交易 时外汇投资者须盯盘"
                      : index == 4
                      ? "严格控制风险，杜绝过度交易以及不严格执 行止损等问题"
                      : index == 5
                      ? "智能化的编程，省略了分析面的过程，分析 速度远远大于人类"
                      : "保障盈利的综合率，EA智能外汇全自动交易 系统最重要的优 点，稳定盈利。融合了众多 外汇高手的智慧与经验，严格的止损和风险 控制"
                  }}
                </div>
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>

    <div class="home-fourthly">
      <img class="home-fourthly-back" src="../assets/home-swiper-3.png" />

      <div class="home-fourthly-block">
        <div class="home-fourthly-block-advantage">
          <div class="home-fourthly-block-advantage-title">公司简介</div>
          <div class="home-fourthly-block-advantage-text">COMPANY PROFILE</div>
        </div>

        <div class="home-fourthly-block-text"></div>

        <div class="home-fourthly-block-span">
          深圳市汇智启航科技有限公司作为一家专注于金融衍生品行业一站式服务系统提供商，我们致力于为全球金融机构、投资机构及专业交易者提供全方位、定制化的解决方案，助力客户在瞬息万变的金融市场中稳健前行。
        </div>

        <div class="home-fourthly-block-span" style="margin-top: 10px;">
          自2021年4月22日成立以来，汇智启航科技便深耕于金融衍生品技术领域，凭借卓越的技术创新能力、深厚的行业理解以及对市场动态的敏锐洞察，我们成功打造了多套高效、稳定、安全的金融衍生品系统。这些系统不仅覆盖了期货、期权、互换、远期合约等主流金融衍生品，还不断拓展至更多复杂衍生品领域，以满足市场日益增长的多元化需求。
        </div>
      </div>
    </div>

    <div class="home-fifth">
      <img class="home-fifth-back" src="../assets/home-swiper-4.png" />

      <div class="home-fifth-block">
        <div class="home-fifth-block-advantage">
          <div class="home-fifth-block-advantage-title">风控讲堂</div>
          <div class="home-fifth-block-advantage-text">RISK MANAGEMENT</div>
        </div>

        <div
          class="home-fifth-block-array"
          v-for="(item, index) in 3"
          :key="index"
          @click="prevention(index)"
        >
          <div class="home-fifth-block-array-back">
            <img
              class="home-fifth-block-array-back-img"
              :src="
                index == 0
                  ? require('../assets/pneumatic1.png')
                  : index == 1
                  ? require('../assets/pneumatic2.png')
                  : require('../assets/pneumatic3.png')
              "
            />
            <img
              class="home-fifth-block-array-back-play"
              src="../assets/pneumatic4.png"
            />
          </div>

          <div class="home-fifth-block-array-block">
            <div class="home-fifth-block-array-block-title">
              {{
                index == 0
                  ? "外汇是什么？外汇基础概念讲解"
                  : index == 1
                  ? "外汇入门讲解"
                  : "《MT4EA》5、风控EA"
              }}
            </div>
            <div class="home-fifth-block-array-block-text">
              {{ index == 0 ? "356" : index == 1 ? "633" : "284" }}人观看
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="home-sixth">
      <img class="home-sixth-back" src="../assets/home-swiper-5.png" />

      <div class="home-sixth-block">
        <div class="home-sixth-block-advantage">
          <div class="home-sixth-block-advantage-title">一起成长，追梦理想</div>
          <div class="home-sixth-block-advantage-text">
            GROW UP TOGETHER AND PURSUE DREAMS
          </div>
        </div>

        <div class="home-sixth-block-title">
          共事最亲民的老板，遇见最极客的技术牛人加入富有激情、勇于创新的青年团队
        </div>

        <div
          class="home-sixth-block-array"
          v-for="(item, index) in 3"
          :key="index"
        >
          <div class="home-sixth-block-array-left">
            <img
              :src="
                index == 0
                  ? require('../assets/home-swiper-5-1.png')
                  : index == 1
                  ? require('../assets/home-swiper-5-2.png')
                  : require('../assets/home-swiper-5-3.png')
              "
              alt=""
            />
          </div>
          <div class="home-sixth-block-array-right">
            <div class="home-sixth-block-array-right-title">
              {{
                index == 0 ? "薪资待遇" : index == 1 ? "员工福利" : "职业规划"
              }}
            </div>
            <div class="home-sixth-block-array-right-text">
              {{
                index == 0
                  ? "业内有竞争力的薪酬、激励奖金计划、年终优秀员工奖励制度，对优秀的人才将给予公司股票期权，让每一位员工充分实现自身的价值。"
                  : index == 1
                  ? "五险一金、带薪假期、年度体检、年度旅游、团队聚餐、多样化的文体娱乐活动与舒适的办公环境。快乐地工作生活，我们不断在努力。"
                  : "入职培训、技能培训、定期的内部分享交流与团队拓展培训。公司处于快速发展期与扩大阶段，我们为优秀的员工提供岗位晋升的机会。 "
              }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="home-seventh">
      <tab-bar></tab-bar>
    </div>
  </div>
</template>

<script>
import navBar from "../components/nav-bar.vue";
import tabBar from "../components/tab-bar.vue";

export default {
  components: { navBar, tabBar },
  data() {
    return {
      active: 0,
    };
  },
  mounted() {
    this.backTop();
    window.onload = function () {
      document.addEventListener("touchstart", function (e) {
        if (e.touches.length > 1) {
          e.preventDefault();
        }
      });
      document.addEventListener("gesturestart", function (e) {
        e.preventDefault();
      });
    };
  },
  methods: {
    // 点击对应的风控事件
    prevention(index) {
      if (index == 0) {
        window.open(
          "https://haokan.baidu.com/v?pd=wisenatural&vid=4552773370316071104"
        );
      } else if (index == 1) {
        window.open(
          "https://haokan.baidu.com/v?pd=wisenatural&vid=3489526177582554702"
        );
      } else {
        window.open("https://www.bilibili.com/video/BV1cL411b7zE");
      }
    },
    backTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  .home-first {
    height: 50px;
    width: 100%;
  }

  .home-second {
    width: 100%;
    height: 475px;

    /deep/ .van-swipe {
      height: 475px;
    }
    /deep/ .van-swipe__indicators {
      bottom: 15px;
    }

    /deep/ .van-swipe__indicator {
      width: 15px;
      height: 2.5px;
      background: rgba(0, 0, 0, 0.3);
    }

    .home-second-swiper1 {
      height: 100%;
      position: relative;

      .swiper1-img {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        object-fit: fill;
      }

      .swiper1-block {
        position: relative;
        padding-top: 32.5px;

        .swiper1-block-title {
          font-size: 20px;
          font-family: Helvetica;
          color: #ffffff;
          text-align: center;
          font-weight: bold;
        }
        .swiper1-block-text {
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          margin-top: 10.5px;
          margin-bottom: 45px;
          text-align: center;
        }
        .swiper1-block-list {
          margin-bottom: 23px;
          padding-left: 73.5px;
          box-sizing: border-box;
          .swiper1-block-list-num {
            font-size: 30px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;

            span {
              font-size: 21.5px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #f60646;
            }
          }

          .swiper1-block-list-text {
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
          }
        }

        .swiper1-block-list:last-child {
          margin-bottom: 0;
        }
        // 第一部分轮播图 第二模块
        .swiper1-block-display {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin: 0 23px;
          .swiper1-block-display-array {
            width: 162px;
            height: 162px;
            background: linear-gradient(141deg, #e6f0fe 0%, #e6ebfe 100%);
            border-radius: 8px;
            margin-top: 5px;
            padding: 0 13px;
            box-sizing: border-box;

            .swiper1-block-display-array-title {
              font-size: 16px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 600;
              color: #333333;
              text-align: center;
              margin-top: 11px;
            }
            .swiper1-block-display-array-img {
              text-align: center;
              margin-top: 6.5px;
              margin-bottom: 10.5px;
              img {
                width: 51px;
                height: 49px;
              }
            }
            .swiper1-block-display-array-text {
              font-size: 11px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 16px;
              text-align: left;
            }
          }
        }

        .swiper1-block-watch {
          display: flex;
          align-items: center;
          margin-top: 18.5px;
          justify-content: space-between;
          padding: 0 25.5px;
          box-sizing: border-box;

          .swiper1-block-watch-array {
            text-align: center;
            .swiper1-block-watch-array-img {
              width: 22.5px;
              height: 22.5px;
            }
            .swiper1-block-watch-array-title {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              margin-top: 4.5px;
            }
          }
        }

        // 第一部分轮播图 第三模块
        .swiper1-block-advantage {
          height: 37px;
          position: relative;
          display: flex;
          justify-content: center;
          .swiper1-block-advantage-title {
            font-size: 27.5px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 600;
            color: #333333;
          }
          .swiper1-block-advantage-text {
            font-size: 17.5px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            position: absolute;
            bottom: -5px;
            opacity: 0.3;
            letter-spacing: 1px;
          }
        }
        .swiper1-block-interval {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 18px;
          padding: 0 24px;
          box-sizing: border-box;
          margin-top: 19.5px;
        }
        .swiper1-block-advantagelist {
          margin: 0 24px;
          margin-top: 20px;

          .swiper1-block-advantagelist-array {
            position: relative;
            height: 56px;
            margin-bottom: 6px;
            display: flow-root;
            .swiper1-block-advantagelist-array-img {
              position: absolute;
              display: flex;
              height: 56px;
              object-fit: fill;
              width: 100%;
            }
            .swiper1-block-advantagelist-array-title {
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 600;
              color: #ffffff;
              position: relative;
              margin-top: 8px;
              margin-left: 12.5px;
            }
            .swiper1-block-advantagelist-array-text {
              font-size: 11px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 500;
              color: #ffffff;
              margin-top: 6px;
              position: relative;
              margin-left: 12.5px;
            }
          }

          .swiper1-block-advantagelist-array:last-child {
            margin-bottom: 0;
          }
        }

        // 第一部分轮播图 第四模块
        .swiper1-block-div {
          margin-top: 19.5px;

          .swiper1-block-div-text {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 18px;
            text-align: center;
          }
        }
        .swiper1-block-table {
          margin: 0 35px;
          margin-top: 30px;
          .swiper1-block-table-array {
            display: flex;
            margin-bottom: 21px;

            .swiper1-block-table-array-left {
              width: 65.5px;
              height: 51px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .swiper1-block-table-array-right {
              width: 228px;
              margin-left: 9px;
              .swiper1-block-table-array-right-title {
                font-size: 16px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
              }
              .swiper1-block-table-array-right-text {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                margin-top: 1.5px;
              }
            }
          }
        }

        // 第一部分轮播图 第五模块
        .swiper1-block-location {
          margin-top: 31px;
          .swiper1-block-location-array {
            width: 270px;
            text-align: left;
            margin-bottom: 21px;
            float: right;
            margin-right: 30px;
            .swiper1-block-location-array-title {
              font-size: 16px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #333333;
              text-align: right;
            }
            .swiper1-block-location-array-text {
              font-size: 11px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 16px;
              margin-top: 10px;
              letter-spacing: 0.2px;
            }
          }

          .swiper1-block-location-array:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .home-thirdly {
    width: 100%;
    height: 100%;
    .home-thirdly-tab {
      height: 100%;
      width: 100%;

      /deep/ .van-tabs {
        background: linear-gradient(174deg, #74a7eb 0%, #597bdf 100%);
        padding: 30px 0;
        box-sizing: border-box;
      }

      /deep/ .van-tabs__line {
        display: none;
      }

      /deep/ .van-tabs--line .van-tabs__wrap {
        height: 100px;
      }

      /deep/ .van-tabs__nav {
        background-color: #71a2ea;
        margin: 0 12.5px;
        justify-content: space-around;
      }

      /deep/ .van-tab {
        width: 50px;
        height: 50px;
        flex: none;
        padding: 0;
        line-height: 0;
      }

      /deep/ .van-tab--active {
        width: 100px;
        height: 100px;
      }

      .home-thirdly-tab-img {
        width: 100%;
        height: 100%;
      }

      .home-thirdly-tab-center {
        margin: 0 24px;
        margin-top: 23.5px;
        .home-thirdly-tab-center-list {
          margin-bottom: 23.5px;
          .home-thirdly-tab-center-list-title {
            font-size: 17px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
          }
          .home-thirdly-tab-center-list-text {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 18px;
            margin-top: 3px;
            opacity: 0.9;
          }
        }

        .home-thirdly-tab-center-list:last-child {
          margin-bottom: 0;
        }

        // 第四模块
        .home-thirdly-tab-center-title {
          font-size: 30px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
        }
        .home-thirdly-tab-center-text {
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          margin-top: 6px;
        }

        .home-thirdly-tab-center-array {
          margin-top: 30px;
          .home-thirdly-tab-center-array-title {
            font-size: 17px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
          }
          .home-thirdly-tab-center-array-text {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            opacity: 0.9;
            margin-top: 3.5px;
            letter-spacing: 0.2px;
          }

          // 第五模块
          .home-thirdly-tab-center-array-left {
            display: flex;
            align-items: flex-end;
            height: 34px;

            .home-thirdly-tab-center-array-left-num {
              font-size: 20.5px;
              font-family: PingFang-SC-Heavy, PingFang-SC;
              font-weight: 800;
              color: #ffffff;
              line-height: 42px;
            }

            .home-thirdly-tab-center-array-left-lint {
              width: 12.5px;
              height: 12.5px;
              background: #f3ca82;
            }
          }

          .home-thirdly-tab-center-array-right {
            width: 290px;
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 21px;
            letter-spacing: 0.2px;
            opacity: 0.9;
          }
        }
        .home-thirdly-tab-center-div {
          margin-top: 42px;
          .home-thirdly-tab-center-div-span {
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            letter-spacing: 0.2px;
            opacity: 0.9;
            margin-bottom: 20px;
          }

          .home-thirdly-tab-center-div-span:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .home-fourthly {
    width: 100%;
    padding-bottom: 40px;
    position: relative;
    .home-fourthly-back {
      position: absolute;
      display: flex;
      width: 100%;
      height: 100%;
      object-fit: fill;
    }

    .home-fourthly-block {
      position: relative;
      width: 100%;
      padding-top: 30px;
      box-sizing: border-box;

      .home-fourthly-block-advantage {
        height: 37px;
        position: relative;
        display: flex;
        justify-content: center;
        .home-fourthly-block-advantage-title {
          font-size: 27.5px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 600;
          color: #ffffff;
        }
        .home-fourthly-block-advantage-text {
          font-size: 17.5px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          position: absolute;
          bottom: -5px;
          opacity: 0.3;
          letter-spacing: 1px;
        }
      }

      .home-fourthly-block-text {
        font-size: 17px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        margin-top: 19.5px;
        margin-bottom: 19.5px;
      }

      .home-fourthly-block-span {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        margin: 0 24px;
        line-height: 20.5px;
      }
    }
  }
  .home-fifth {
    width: 100%;
    position: relative;
    padding-bottom: 50px;
    .home-fifth-back {
      position: absolute;
      display: flex;
      width: 100%;
      height: 100%;
      object-fit: fill;
    }

    .home-fifth-block {
      position: relative;
      width: 100%;
      padding-top: 40px;
      box-sizing: border-box;

      .home-fifth-block-advantage {
        height: 37px;
        position: relative;
        display: flex;
        justify-content: center;
        margin-bottom: 23px;
        .home-fifth-block-advantage-title {
          font-size: 27.5px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 600;
          color: #333333;
        }
        .home-fifth-block-advantage-text {
          font-size: 17.5px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          position: absolute;
          bottom: -5px;
          opacity: 0.3;
          letter-spacing: 1px;
        }
      }

      .home-fifth-block-array {
        height: 287px;
        background: #ffffff;
        box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.08);
        border-radius: 5.5px;
        margin: 0 24px;
        margin-bottom: 15px;

        .home-fifth-block-array-back {
          width: 100%;
          position: relative;
          height: 210.5px;
          display: flex;
          justify-content: center;
          align-items: center;
          .home-fifth-block-array-back-play {
            position: relative;
            width: 50px;
            height: 50px;
          }
          .home-fifth-block-array-back-img {
            width: 100%;
            height: 100%;
            position: absolute;
            display: flex;
            object-fit: fill;
            border-radius: 5.5px 5.5px 0 0;
          }
        }

        .home-fifth-block-array-block {
          padding: 0 21px;
          box-sizing: border-box;
          margin-top: 10.5px;
          .home-fifth-block-array-block-title {
            font-size: 19px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 600;
            color: #333333;
            letter-spacing: 1px;
          }
          .home-fifth-block-array-block-text {
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #b4b4b4;
            margin-top: 6px;
            letter-spacing: 1px;
          }
        }
      }

      .home-fifth-block-array:last-child {
        margin-bottom: 0;
      }
    }
  }
  .home-sixth {
    width: 100%;
    position: relative;
    padding-bottom: 50px;
    .home-sixth-back {
      position: absolute;
      display: flex;
      width: 100%;
      height: 100%;
      object-fit: fill;
    }

    .home-sixth-block {
      position: relative;
      width: 100%;
      padding-top: 40px;
      box-sizing: border-box;

      .home-sixth-block-advantage {
        height: 37px;
        position: relative;
        display: flex;
        justify-content: center;
        margin-bottom: 16px;
        .home-sixth-block-advantage-title {
          font-size: 27.5px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 600;
          color: #ffffff;
        }
        .home-sixth-block-advantage-text {
          font-size: 14.5px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2481d7;
          position: absolute;
          bottom: -5px;
          opacity: 0.3;
          letter-spacing: 1px;
        }
      }

      .home-sixth-block-title {
        width: 266px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 23px;
        margin: 0 auto;
        text-align: center;
        opacity: 0.7;
        margin-bottom: 30px;
      }

      .home-sixth-block-array {
        display: flex;
        margin: 0 24px;
        margin-bottom: 20.5px;
        justify-content: space-between;
        .home-sixth-block-array-left {
          width: 75px;
          height: 75px;

          img {
            width: 100%;
            height: 100%;
          }
        }
        .home-sixth-block-array-right {
          width: 239px;
          .home-sixth-block-array-right-title {
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
          }
          .home-sixth-block-array-right-text {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 19px;
            margin-top: 2px;
            letter-spacing: 1px;
          }
        }
      }
    }
  }
}
</style>
