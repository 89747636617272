<template>
  <div class="particulars">
    <img class="particulars-back" src="../assets/system-8.png" alt="" />
    <div class="particulars-headline">
      <div class="headline-left" @click="particularsBack">
        <img src="../assets/system-9.png" alt="" />
      </div>
      <div class="headline-title"></div>
      <div class="headline-left"></div>
    </div>
    <div class="particulars-headline-lint"></div>

    <div class="particulars-status">
      <img
        class="status-img"
        :src="
          particularsObj.status == 0
            ? require('../assets/order-2.png')
            : particularsObj.status == 1
            ? require('../assets/order-0.png')
            : particularsObj.status == 2
            ? require('../assets/order-1.png')
            : ''
        "
        alt=""
      />
      <div class="status-title">
        {{
          particularsObj.status == 0
            ? "待支付"
            : particularsObj.status == 1
            ? "已支付"
            : particularsObj.status == 2
            ? "已放弃"
            : ""
        }}
      </div>
    </div>

    <div class="particulars-block">
      <div class="block-title">商品信息</div>
      <div
        v-for="(item, index) in particularsObj.serialNumberList"
        :key="index"
      >
        <div class="block-display">
          <div class="display-left">
            <img :src="particularsObj.goodUrls" alt="" />
          </div>
          <div class="display-right">
            <div class="right-title">{{ particularsObj.goodName }}</div>
            <div class="right-text">
              有效期： <span v-if="item.termId == 31">1个月</span>
              <span v-if="item.termId == 93">3个月</span>
              <span v-if="item.termId == 186">6个月</span>
              <span v-if="item.termId == 366">1年</span>
              <span v-if="item.termId == 732">2年</span>
              <span v-if="item.termId == 1098">3年</span>
            </div>
            <div class="right-text">
              序列号：{{ item.number }}
              <span class="copy" @click="copyStop(item.number)">复制</span>
            </div>
          </div>
        </div>
        <div class="block-view">
          <div
            class="view-array"
            v-for="(o, j) in 2"
            :key="j"
            @click.stop="downloadStop(item, index)"
          >
            {{ j == 0 ? "商品编号" : "软件下载" }}：
            {{ j == 0 ? item.goodCode : particularsObj.goodName }}
          </div>
        </div>

        <div class="block-commodity">
          <div class="commodity-list" v-for="(i, n) in 3" :key="n">
            <div class="list-left">
              {{
                index == 0 ? "商品单价" : index == 1 ? "购买数量" : "商品总价"
              }}
            </div>
            <div class="list-right">
              {{
                index == 0
                  ? "¥" + particularsObj.price
                  : index == 1
                  ? "1"
                  : "¥" + particularsObj.tprice
              }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="particulars-message">
      <div class="message-title">订单信息</div>

      <div class="message-list" v-for="(item, index) in 3" :key="index">
        <div class="list-left">
          {{
            index == 0 ? "订单编号" : index == 1 ? "下单时间" : "订单取消时间"
          }}
        </div>
        <div class="list-right">
          {{
            index == 0
              ? particularsObj.orderNum
              : index == 1
              ? particularsObj.createTime
              : particularsObj.payTime
          }}

          <span
            v-if="index == 0"
            class="copy"
            @click="copyStop(particularsObj.orderNum)"
            >复制</span
          >
        </div>
      </div>
    </div>

    <div class="particulars-bottom" v-if="particularsObj.status == 0">
      <div class="bottom-display">
        <div
          class="button"
          v-for="(item, index) in 2"
          :key="index"
          @click="functionStop(index)"
        >
          {{ index == 0 ? "取消订单" : "立即付款" }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { usergoodorderIdGet, usergoodorderIdPost } from "../api/index";

export default {
  data() {
    return {
      particularsId: "",
      particularsObj: {},
    };
  },
  created() {
    this.particularsId = this.$route.query.id;

    this.usergoodorderIdGetMet();
  },
  mounted() {
    this.backTop()
    window.onload = function () {
      document.addEventListener("touchstart", function (e) {
        if (e.touches.length > 1) {
          e.preventDefault();
        }
      });
      document.addEventListener("gesturestart", function (e) {
        e.preventDefault();
      });
    };
  },
  methods: {
    backTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    usergoodorderIdGetMet() {
      let params = this.particularsId;

      usergoodorderIdGet(params).then((res) => {
        if (res.code == 200) {
          let list =
            res.result.goodUrl == null ? [] : res.result.goodUrl.split(",");
          res.result.goodUrls =
            "https://gongafter.gjlike.com/jeecg-boot/" + list[0];

          this.particularsObj = res.result;
        }
      });
    },

    //----------------------------
    particularsBack() {
      this.$router.go(-1);
    },
    downloadStop(item, index) {
      if (index == 1) {
        window.open(this.particularsObj.pcUrl);
      }
    },
    copyStop(texts) {
      const input = document.createElement("input");
      input.value = texts; // 设置要复制的文本
      document.body.appendChild(input); // 添加input元素
      input.select(); // 选中文本
      document.execCommand("copy"); // 执行复制操作
      document.body.removeChild(input); // 移除input元素
      this.$toast.success("已复制");
    },
    //--------按钮
    functionStop(index) {
      console.log("点击触发", index);

      if (index == 0) {
        let params = this.particularsObj.id;

        usergoodorderIdPost(params).then((res) => {
          if (res.code == 200) {
            this.$toast.success("取消成功！");
            this.usergoodorderIdGetMet();
          }
        });
      } else {
        let price =
          Number(this.particularsObj.num) * Number(this.particularsObj.price);

        this.$router.push({
          path: "/SystemParticularsCashier",
          query: {
            id: this.particularsObj.goodDetailId,
            price: price,
            num: this.particularsObj.num,
          },
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.particulars {
  min-height: 100vh;
  background: #f5f5f5;
  position: relative;
  .particulars-back {
    width: 100%;
    height: 322.5px;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
  }

  .particulars-headline {
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .headline-left {
      width: 25px;
      height: 25px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .headline-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 17px;
      color: #ffffff;
    }
  }
  .particulars-headline-lint {
    width: 100%;
    height: 47px;
  }

  .particulars-status {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    .status-img {
      width: 21px;
      height: 21px;
      margin-right: 6px;
    }

    .status-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 18px;
      color: #ffffff;
    }
  }

  .particulars-block {
    margin: 20px 12px 0;
    padding: 14px 10px;
    box-sizing: border-box;
    position: relative;
    background: #fff;
    border-radius: 10px;

    .block-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 15px;
      color: #222222;
      text-align: left;
    }

    .block-display {
      margin-top: 12px;
      display: flex;
      .display-left {
        width: 82px;
        height: 82px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
          object-fit: contain;
        }
      }

      .display-right {
        margin-left: 10px;
        width: 70%;

        .right-title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #222222;
        }

        .right-text {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #999999;
          margin-top: 4px;
          word-break: break-all;
          line-height: 20px;

          .text {
          }

          .copy {
            padding: 0 5px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 11px;
            color: #2481d7;
            margin-left: 8px;
            border-radius: 2px;
            border: 1px solid #2481d7;
          }
        }
      }
    }

    .block-view {
      margin-top: 10px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .view-array {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #999999;
        word-break: break-all;
      }
    }

    .block-commodity {
      margin: 20px 12px 0;
      padding: 14px 11px;
      background: #f7f7f7;
      box-sizing: border-box;
      border-radius: 5px;

      .commodity-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 11px;

        .list-left {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 13px;
          color: #999999;
        }
        .list-right {
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 13px;
          word-break: break-all;
          color: #333333;

          .copy {
            padding: 0 5px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 11px;
            color: #2481d7;
            margin-left: 8px;
            border-radius: 2px;
            border: 1px solid #2481d7;
          }
        }
      }

      .commodity-list:last-child {
        margin-bottom: 0;
      }
    }
  }

  .particulars-message {
    position: relative;
    margin: 10px 12px 0;
    padding: 14px 10px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 10px;
    .message-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 15px;
      text-align: left;
      color: #222222;
      margin-bottom: 11px;
    }

    .message-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 11px;

      .list-left {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 13px;
        color: #999999;
      }

      .list-right {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 13px;
        color: #333333;
        word-break: break-all;

        .copy {
          padding: 0 5px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 11px;
          color: #2481d7;
          margin-left: 8px;
          border-radius: 2px;
          border: 1px solid #2481d7;
        }
      }
    }
    .message-list:last-child {
      margin-bottom: 0;
    }
  }

  .particulars-bottom {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 56px;
    background: #ffffff;
    .bottom-display {
      display: flex;
      align-items: center;
      height: 100%;
      justify-content: flex-end;

      .button {
        padding: 7px 14px;
        box-sizing: border-box;
        border-radius: 17px;
        border: 1px solid #c9c9c9;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 13px;
        color: #222222;
        margin-right: 10px;
      }

      .button:last-child {
        color: #2481d7;
        border: 1px solid #2481d7;
      }
    }
  }
}
</style>