<template>
  <div class="product">
    <div class="product-first">
      <nav-bar></nav-bar>
    </div>

    <div class="product-second">
      <img class="product-second-back" src="../assets/product1.png" />
      <div class="product-second-block">
        <div class="product-second-block-title">EA产品介绍</div>
        <div class="product-second-block-text">EA PRODUCT INTRODUCTION</div>
      </div>
    </div>

    <div class="product-thirdly">
      <div class="product-thirdly-list" v-for="(item, index) in 4" :key="index">
        <img
          class="product-thirdly-list-img"
          :src="
            index == 0
              ? require('../assets/product1-1.png')
              : index == 1
              ? require('../assets/product1-2.png')
              : index == 2
              ? require('../assets/product1-3.png')
              : require('../assets/system-0.png')
          "
        />
        <div class="product-thirdly-list-title">
          {{
            index == 0
              ? "双均线策略(期货)-Python量化"
              : index == 1
              ? "MT4外汇EA交易智能自动交易系统"
              : index == 2
              ? "海龟趋势加仓"
              : "汇智投趋势交易系统"
          }}
        </div>

        <div v-if="index == 0">
          <div
            class="product-thirdly-list-array"
            v-for="(item, index) in 5"
            :key="index"
          >
            <div class="product-thirdly-list-array-text">
              {{
                index == 0
                  ? "1、不需要打开MT4即可完成所有交易 "
                  : index == 1
                  ? "2、支持单平台延迟套利双平台套利和多平台套利 "
                  : index == 2
                  ? "3、软件下单会显示成手动单。"
                  : index == 3
                  ? "4、支持币安OKEX等虚拟币平台。"
                  : "5、对接数据源有MT4MT5、CTRADERFIX、LMAXFIX LMAXAPI、ACTIVFIN、fastmatch、fxcmfixfortexfix ogm fix、cfh fix、dukas fixprimexmfxpigfix trademade fixxenfinfix"
              }}
            </div>
          </div>
        </div>

        <div v-if="index == 1">
          <div
            class="product-thirdly-list-array"
            v-for="(item, index) in 4"
            :key="index"
          >
            <div class="product-thirdly-list-array-text">
              {{
                index == 0
                  ? "1、轻仓带止损"
                  : index == 1
                  ? "2、可支持分批次建仓 "
                  : index == 2
                  ? "3、止损止盈灵活，曲线平稳上涨"
                  : "4、回撤小，完全智能自动化交易程序"
              }}
            </div>
          </div>
        </div>

        <div v-if="index == 2">
          <div
            class="product-thirdly-list-array"
            v-for="(item, index) in 5"
            :key="index"
          >
            <div class="product-thirdly-list-array-text">
              {{
                index == 0
                  ? "1、EA分类极影机器人网格策略，盈利稳定网格策略。"
                  : index == 1
                  ? "2、盈利稳定黑盒B-Box统计套利策略统计套利策略蓝色风暴品种丰富，对冲交易品种丰富，对冲交易金牛座突破追踪"
                  : index == 2
                  ? "3、经典趋势突破追踪，经典趋势百元EA西点延迟EA(带 Lmax账号)"
                  : index == 3
                  ? "4、海龟趋势加仓EA评测西点延迟EA(带Lmax账号) "
                  : "5、海龟趋势加仓EA评测免费工具跟单EA28货币EA切换MT4背景跟单EA28货币EA切换MT4背景EA定制和VPS租用EA定制。"
              }}
            </div>
          </div>
        </div>

        <div v-if="index == 3">
          <div
            class="product-thirdly-list-array"
            v-for="(item, index) in 3"
            :key="index"
          >
            <div
              class="product-thirdly-list-array-text"
              style="font-weight: bold; font-size: 14px"
            >
              {{
                index == 0
                  ? "1、DMI指标"
                  : index == 1
                  ? "2、MACD指标"
                  : index == 2
                  ? "3、RSI指标"
                  : ""
              }}
            </div>

            <div class="product-thirdly-list-array-text">
              {{
                index == 0
                  ? "DMI指标又称动量指标，是由多方线PDI红色线，空方线MDI绿色线，趋势强度ADX黄色线，趋势强度均线ADXR白色点状线4条线组成。我们一般只使用PDI,MDI,ADX三种线。"
                  : index == 1
                  ? "MACD指标是一种技术分析工具，‌用于研判买卖股票的时机和预测股票价格涨跌。‌ 主要由DIF快线，DEA慢线，MACD柱线组成。 使用MACD指标时，‌可以通过观察DIF与DEA线的交叉情况来判断辅助"
                  : index == 2
                  ? "RSI指标是震荡指标，主要用法有："
                  : ""
              }}
            </div>

            <div class="product-thirdly-list-array-text" v-if="index == 2">
              ① 超买或超卖区间。
              当处于超买或超卖区时，市场大概率会反转。如：当RSI大于70时，此时处于超买，此时不建议开多单。
              RSI小于30时，此时处于超卖，此时不建议开空单。
            </div>
            <div class="product-thirdly-list-array-text" v-if="index == 2">
              ② 背离，
              当价格创下新高(低)，但RSI并不处于新高(低)时，此时为背离，通常表明市场大概率会出现反转。
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="product-fourthly">
      <tab-bar></tab-bar>
    </div>
  </div>
</template>

<script>
import navBar from "../components/nav-bar.vue";
import tabBar from "../components/tab-bar.vue";

export default {
  components: { navBar, tabBar },
  mounted() {
    this.backTop();
    window.onload = function () {
      document.addEventListener("touchstart", function (e) {
        if (e.touches.length > 1) {
          e.preventDefault();
        }
      });
      document.addEventListener("gesturestart", function (e) {
        e.preventDefault();
      });
    };
  },

  methods: {
    backTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.product {
  .product-first {
    height: 50px;
    width: 100%;
  }
  .product-second {
    width: 100%;
    position: relative;
    height: 128.5px;
    padding-top: 40px;
    box-sizing: border-box;
    .product-second-back {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: fill;
      display: flex;
      top: 0;
    }
    .product-second-block {
      height: 37px;
      position: relative;
      display: flex;
      justify-content: center;
      .product-second-block-title {
        font-size: 27.5px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 600;
        color: #ffffff;
      }
      .product-second-block-text {
        font-size: 17.5px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        position: absolute;
        bottom: -5px;
        opacity: 0.3;
        letter-spacing: 1px;
      }
    }
  }
  .product-thirdly {
    margin: 0 24px;
    margin-top: 26px;
    padding-bottom: 40px;
    .product-thirdly-list {
      margin-bottom: 30px;
      .product-thirdly-list-img {
        width: 327px;
        height: 218px;
      }
      .product-thirdly-list-title {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .product-thirdly-list-array {
        .product-thirdly-list-array-text {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 17px;
        }
      }
    }

    .product-thirdly-list:last-child {
      margin-bottom: 0;
    }
  }
  .product-fourthly {
  }
}
</style>