import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


Vue.config.productionTip = false

import { Button, DropdownMenu, DropdownItem, Swipe, SwipeItem, Tab, Tabs, Cell, CellGroup, List, Toast, Empty, Uploader, Field , Popup , Dialog    } from 'vant' // 引入Button组件
Vue.use(Button) // 全局注册 
Vue.use(DropdownMenu) // 全局注册 
Vue.use(DropdownItem) // 全局注册 
Vue.use(Swipe) // 全局注册 
Vue.use(SwipeItem) // 全局注册 

Vue.use(Tab) // 全局注册 
Vue.use(Tabs) // 全局注册 
Vue.use(Cell) // 全局注册 
Vue.use(CellGroup) // 全局注册 
Vue.use(List);
Vue.use(Toast);
Vue.use(Empty);
Vue.use(Uploader);
Vue.use(Field);
Vue.use(Popup);

Vue.use(Dialog);

import axios from "axios";
import qs from 'qs';
Vue.prototype.$qs = qs;
Vue.prototype.$axios = axios;
Vue.prototype.$http = axios



import 'element-ui/lib/theme-chalk/index.css'
import element from './plugins/element'
Vue.use(element)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
