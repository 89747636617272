import { render, staticRenderFns } from "./information.vue?vue&type=template&id=2fc7aab9&scoped=true&"
import script from "./information.vue?vue&type=script&lang=js&"
export * from "./information.vue?vue&type=script&lang=js&"
import style0 from "./information.vue?vue&type=style&index=0&id=2fc7aab9&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fc7aab9",
  null
  
)

export default component.exports