<template>
  <div class="data">
    <div class="data-headline">
      <div class="headline-left" @click="dataBack">
        <img src="../assets/system-14.png" alt="" />
      </div>
      <div class="headline-title">个人资料</div>
      <div class="headline-left"></div>
    </div>

    <div class="data-headline-lint"></div>

    <div class="data-profile">
      <div class="profile-list" v-for="(item, index) in 3" :key="index">
        <div class="list-left">
          {{
            index == 0
              ? "头像"
              : index == 1
              ? "昵称"
              : index == 2
              ? "已绑手机号"
              : ""
          }}
        </div>
        <div class="list-right">
          <van-uploader
            :after-read="afterRead"
            :before-read="beforeRead"
            v-if="index == 0"
            style="margin-right: 19px"
          >
            <div class="right-photo">
              <img
                :src="
                  infoObj.avatar == null
                    ? require('../assets/personage-9.png')
                    : infoObj.avatar
                "
                alt=""
              />
            </div>
          </van-uploader>

          <div class="right-display" v-if="index == 1">
            <div class="title">{{ infoObj.username }}</div>
            <img src="../assets/personage-8.png" alt="" />
          </div>
          <div class="right-title" v-if="index == 2">{{ infoObj.phone }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userInfoGet } from "../api/index";
import { userAvatar, Uploader } from "../api/login";

export default {
  data() {
    return {
      infoObj: {},
    };
  },
  created() {
    this.personageInfo();
  },
  mounted() {
    this.backTop()
    window.onload = function () {
      document.addEventListener("touchstart", function (e) {
        if (e.touches.length > 1) {
          e.preventDefault();
        }
      });
      document.addEventListener("gesturestart", function (e) {
        e.preventDefault();
      });
    };
  },
  methods: {
    backTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    personageInfo() {
      let params = {};

      userInfoGet(params).then((res) => {
        if (res.code == 200) {
          this.infoObj = res.result;
        }
      });
    },
    //--------------
    beforeRead(file) {
      if (file.size > 10 * 1024 * 1024) {
        this.$toast("文件大小不能超过 10MB");
        return false;
      }
      return true;
    },
    afterRead(file) {
      // 创建 FormData
      console.log("查看文件上传后", file);

      // let str = 'https://gongafter.gjlike.com/jeecg-boot/'+ file.file.name
      // this.userAvatarMet(str)

      const formData = new FormData();
      formData.append("file", file.file); // 这里的 'file' 是后端接收文件的字段名

      this.$http
        .post(
          "https://gongafter.gjlike.com/jeecg-boot/sys/common/upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          // 文件上传成功的处理逻辑
          let str =
            "https://gongafter.gjlike.com/jeecg-boot/" +
            response.data.message;

          this.userAvatarMet(str);
        })
        .catch((error) => {
          // 文件上传失败的处理逻辑
          this.$toast.error(error);
        });
    },
    userAvatarMet(str) {
      let params = {
        accessToken: "",
        avatar: str,
        createBy: "",
        createTime: "",
        id: 0,
        password: "",
        phone: "",
        realname: "",
        salt: "",
        status: 0,
        sysOrgCode: "",
        updateBy: "",
        updateTime: "",
        username: "",
      };

      userAvatar(params).then((res) => {
        if (res.code == 200) {
          this.$toast.success("更换成功！");
          this.personageInfo();
        }
      });
    },
    //--------------------
    dataBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.data {
  min-height: 100vh;
  background: #f5f5f5;

  .data-headline {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 9;
    left: 0;
    padding: 0 15px;
    box-sizing: border-box;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f5f5f5;

    .headline-left {
      width: 25px;
      height: 25px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .headline-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 17px;
      color: #222222;
    }
  }
  .data-headline-lint {
    height: 47px;
  }
  .data-profile {
    background: #ffffff;
    padding: 0 12px;
    box-sizing: border-box;
    .profile-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 58px;
      padding: 8px 0;
      box-sizing: border-box;
      border-bottom: 1px solid #ececed;

      .list-left {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 15px;
        color: #454545;
      }
      .list-right {
        .right-photo {
          width: 64px;
          height: 64px;
          border-radius: 50%;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .right-display {
          display: flex;
          align-items: center;

          .title {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 15px;
            color: #888888;
          }

          img {
            width: 18px;
            height: 18px;
          }
        }

        .right-title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 15px;
          color: #888888;
          margin-right: 18px;
        }
      }
    }

    .profile-list:last-child {
      border-bottom: 0;
    }
  }
}
</style>