<template>
  <div class="common">
    <div class="common-headline">
      <div class="headline-left" @click="commonBack">
        <img src="../assets/system-14.png" alt="" />
      </div>
      <div class="headline-title">修改手机号码</div>
      <div class="headline-left"></div>
    </div>
    <div class="common-headline-lint"></div>

    <div class="common-block">
      <div class="block-list" v-for="(item, index) in 5" :key="index">
        <div class="list-left">
          {{
            index == 0
              ? "账号"
              : index == 1
              ? "手机号"
              : index == 2
              ? "验证码"
              : index == 3
              ? "新手机号"
              : index == 4
              ? "验证码"
              : index == 5
              ? ""
              : ""
          }}
        </div>
        <div class="list-center">
          <div class="center-title" v-if="index == 0">{{ oldName }}</div>
          <div class="center-input" v-if="index == 1">
            <van-field v-model="oldPhone" placeholder="请输入旧手机号" />
          </div>
          <div class="center-input" v-if="index == 2">
            <van-field
              v-model="oldVerification"
              placeholder="请输入旧手机号验证码"
            />
          </div>
          <div class="center-input" v-if="index == 3">
            <van-field v-model="newPhone" placeholder="输入新手机号码" />
          </div>
          <div class="center-input" v-if="index == 4">
            <van-field
              v-model="newVerification"
              placeholder="请输入旧手机号验证码"
            />
          </div>
        </div>
        <div class="list-right">
          <div
            v-if="index == 2"
            class="right-verification"
            :style="{
              color: oldPhone != '' ? '#fff' : '',
              background: oldPhone != '' ? '#2481D7' : '',
            }"
            @click="verificationClick"
          >
            {{ count == 0 ? "获取验证码" : count + "秒" }}
          </div>

          <div
            v-if="index == 4"
            class="right-verification"
            :style="{
              color: newPhone != '' ? '#fff' : '',
              background: newPhone != '' ? '#2481D7' : '',
            }"
            @click="newverificationClick"
          >
            {{ count == 0 ? "获取验证码" : count + "秒" }}
          </div>
        </div>
      </div>
    </div>

    <div
      class="common-affirm"
      :class="{
        'affirm-complete':
          oldPhone != '' &&
          oldVerification != '' &&
          newPhone != '' &&
          newVerification != '',
      }"
      @click="replacePhone"
    >
      确认修改
    </div>
  </div>
</template>

<script>
import {
  userRegister,
  userforget,
  userforgetup,
  userPhone,
  postcode,
} from "../api/login";

import { userInfoGet } from "../api/index";

var throttle = require("../plugins/throttle"); // 防抖
var verify = require("../plugins/verify"); // 手机号校验

export default {
  data() {
    return {
      commonIndex: 0,
      //--------------
      oldName: "", // 旧的昵称
      oldPhone: "", // 旧的手机号
      oldVerification: "", // 旧的验证码
      newPhone: "", // 新的手机号
      newVerification: "", // 新的验证码

      count: 0, // 旧的验证码倒计时
      timer: null, // 旧的验证码倒计时
      newCount: 0, // 新的验证码倒计时
      newTimer: null, // 新的验证码倒计时
      sourceIndex: Number, // 新的验证码倒计时
      //---------------
    };
  },
  created() {
    this.userInfoGetMet();
  },
  mounted() {
    this.backTop()
    window.onload = function () {
      document.addEventListener("touchstart", function (e) {
        if (e.touches.length > 1) {
          e.preventDefault();
        }
      });
      document.addEventListener("gesturestart", function (e) {
        e.preventDefault();
      });
    };
  },
  methods: {
    backTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    userInfoGetMet() {
      let params = {};

      userInfoGet(params).then((res) => {
        if (res.code == 200) {
          this.oldName = res.result.username;
        }
      });
    },
    //------------
    commonBack() {
      this.$router.go(-1);
    },

    //-------------更换手机号
    verificationClick() {
      if (this.count != 0) {
        return;
      }

      if (this.oldPhone == "") {
        this.$message.warning("请先输入手机号！");
        return;
      }

      if (!verify.codeVerification(this.oldPhone)) {
        this.$message.warning("手机号格式有误！");
        return;
      }

      this.generationVerificationMet(0);
    },
    newverificationClick() {
      if (this.newCount != 0) {
        return;
      }

      if (this.newPhone == "") {
        this.$message.warning("请先输入手机号！");
        return;
      }
      if (!verify.codeVerification(this.newPhone)) {
        this.$message.warning("手机号格式有误！");
        return;
      }

      this.generationVerificationMet(1);
    },
    //------------验证码
    generationVerificationMet: throttle.throttle(function (index) {
      let params = {
        phone: index == 0 ? this.oldPhone : this.newPhone,
        type: 2,
        // commonIndex == 0 更换手机号
      };

      postcode(params).then((res) => {
        if (res.message == "") {
          if (index == 0) {
            //倒计时时间
            const TIME_COUNT = 60;
            if (!this.timer) {
              this.count = TIME_COUNT;
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                  this.count--;
                } else {
                  this.show = true;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
          }

          if (index == 1) {
            //倒计时时间
            const TIME_COUNT = 60;
            if (!this.newCount) {
              this.newCount = TIME_COUNT;
              this.newTimer = setInterval(() => {
                if (this.newCount > 0 && this.newCount <= TIME_COUNT) {
                  this.newCount--;
                } else {
                  this.show = true;
                  clearInterval(this.newTimer);
                  this.newTimer = null;
                }
              }, 1000);
            }
          }
        }
      });
    }, 2000),
    // 更换手机号
    replacePhone: throttle.throttle(function (index) {
      let params = {
        phone: this.oldPhone,
        code: this.oldVerification,
        newPhone: this.newPhone,
        newCode: this.newVerification,
      };

      userPhone(params).then((res) => {
        if (res.code == 200) {
          this.$toast({
            message: "修改成功",
            icon: require("../assets/personage-10.png"), // 或者 'custom' 来使用自定义图片
            duration: 2000,
          });
          setTimeout(() => {
            this.commonBack();
          }, 2000);
        }
      });
    }, 2000),
    //-----------------------------------------
  },
};
</script>

<style lang="less" scoped>
.common {
  min-height: 100vh;
  background: #f5f5f5;
  position: relative;

  .common-headline {
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .headline-left {
      width: 25px;
      height: 25px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .headline-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 17px;
      color: #222222;
    }
  }

  .common-headline-lint {
    height: 47px;
    width: 100%;
  }

  .common-block {
    .block-list {
      padding: 0 12px;
      box-sizing: border-box;
      background: #fff;
      height: 58px;
      width: 100%;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ececed;

      .list-left {
        width: 20%;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 15px;
        color: #222222;
      }

      .list-center {
        width: 54%;
        .center-title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 15px;
          color: #222222;
        }

        .center-input {
          width: 185px;

          /deep/ .van-cell {
            padding: 0;
            background: rgba(255, 255, 255, 0);
          }

          /deep/ .van-field__control::placeholder {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 15px;
            color: #888888;
          }
        }
      }

      .list-right {
        width: 20%;
        margin-left: 2%;
        .right-verification {
          width: 85px;
          height: 31px;
          background: #e0e0e0;
          border-radius: 15.5px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 12px;
          color: rgba(255, 255, 255, 1);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .block-list:last-child {
      border-bottom: 0;
    }
  }

  .common-affirm {
    margin: 20px 10px 0;
    height: 46px;
    background: #e6e6e6;
    border-radius: 5px;
    font-family: PingFang-SC, PingFang-SC;
    font-weight: 800;
    font-size: 15px;
    color: #c5c5c5;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1px;
  }

  .affirm-complete {
    background: linear-gradient(90deg, #609ce6 0%, #5084da 100%);
    color: #ffffff;
  }
}
</style>