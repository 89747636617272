import { get, post, put, deletefn } from "../api/request";
// 用户登录
export const userLogin = params => post(`/jeecg-boot/front/user/login`, params)

// 短信
export const postcode = params => post(`/jeecg-boot/front/user/sms`, params)

// 退出
export const userQuit = params => post(`/jeecg-boot/front/user/logout`, params)

// 忘记密码
export const userforget = params => post(`/jeecg-boot/front/user/password/forget`, params)

// 修改密码
export const userforgetup = params => post(`/jeecg-boot/front/user/password/up`, params)

// 更换手机号-确定按钮记得做连点限制，不要出现一连两次请求
export const userPhone = params => post(`/jeecg-boot/front/user/phone`, params)

// 注册接口-记得加点击次数限制
export const userRegister = params => post(`/jeecg-boot/front/user/register`, params)

//t 头像上传
export const userAvatar = params => post(`/jeecg-boot/front/user/avatar`, params)

// 上传
export const Uploader = params => post(`/jeecg-boot/sys/common/upload`, params)