var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"common"},[_c('div',{staticClass:"common-headline"},[_c('div',{staticClass:"headline-left",on:{"click":_vm.commonBack}},[_c('img',{attrs:{"src":require("../assets/system-14.png"),"alt":""}})]),_c('div',{staticClass:"headline-title"},[_vm._v("修改手机号码")]),_c('div',{staticClass:"headline-left"})]),_c('div',{staticClass:"common-headline-lint"}),_c('div',{staticClass:"common-block"},_vm._l((5),function(item,index){return _c('div',{key:index,staticClass:"block-list"},[_c('div',{staticClass:"list-left"},[_vm._v(" "+_vm._s(index == 0 ? "账号" : index == 1 ? "手机号" : index == 2 ? "验证码" : index == 3 ? "新手机号" : index == 4 ? "验证码" : index == 5 ? "" : "")+" ")]),_c('div',{staticClass:"list-center"},[(index == 0)?_c('div',{staticClass:"center-title"},[_vm._v(_vm._s(_vm.oldName))]):_vm._e(),(index == 1)?_c('div',{staticClass:"center-input"},[_c('van-field',{attrs:{"placeholder":"请输入旧手机号"},model:{value:(_vm.oldPhone),callback:function ($$v) {_vm.oldPhone=$$v},expression:"oldPhone"}})],1):_vm._e(),(index == 2)?_c('div',{staticClass:"center-input"},[_c('van-field',{attrs:{"placeholder":"请输入旧手机号验证码"},model:{value:(_vm.oldVerification),callback:function ($$v) {_vm.oldVerification=$$v},expression:"oldVerification"}})],1):_vm._e(),(index == 3)?_c('div',{staticClass:"center-input"},[_c('van-field',{attrs:{"placeholder":"输入新手机号码"},model:{value:(_vm.newPhone),callback:function ($$v) {_vm.newPhone=$$v},expression:"newPhone"}})],1):_vm._e(),(index == 4)?_c('div',{staticClass:"center-input"},[_c('van-field',{attrs:{"placeholder":"请输入旧手机号验证码"},model:{value:(_vm.newVerification),callback:function ($$v) {_vm.newVerification=$$v},expression:"newVerification"}})],1):_vm._e()]),_c('div',{staticClass:"list-right"},[(index == 2)?_c('div',{staticClass:"right-verification",style:({
            color: _vm.oldPhone != '' ? '#fff' : '',
            background: _vm.oldPhone != '' ? '#2481D7' : '',
          }),on:{"click":_vm.verificationClick}},[_vm._v(" "+_vm._s(_vm.count == 0 ? "获取验证码" : _vm.count + "秒")+" ")]):_vm._e(),(index == 4)?_c('div',{staticClass:"right-verification",style:({
            color: _vm.newPhone != '' ? '#fff' : '',
            background: _vm.newPhone != '' ? '#2481D7' : '',
          }),on:{"click":_vm.newverificationClick}},[_vm._v(" "+_vm._s(_vm.count == 0 ? "获取验证码" : _vm.count + "秒")+" ")]):_vm._e()])])}),0),_c('div',{staticClass:"common-affirm",class:{
      'affirm-complete':
        _vm.oldPhone != '' &&
        _vm.oldVerification != '' &&
        _vm.newPhone != '' &&
        _vm.newVerification != '',
    },on:{"click":_vm.replacePhone}},[_vm._v(" 确认修改 ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }