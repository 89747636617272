<template>
  <div class="personage">
    <img class="personage-back" src="../assets/personage-back.png" alt="" />

    <div class="personage-first">
      <nav-bar></nav-bar>
    </div>

    <div class="personage-second">
      <div class="second-head">
        <div class="head-img">
          <img :src="infoObj.avatar == null? require('../assets/personage-9.png'): infoObj.avatar" alt="" />
        </div>
        <div class="head-title">{{ infoObj.username }}</div>
      </div>
    </div>

    <div class="personage-three">
      <div class="three-top">
        <div class="top-left">我的订单</div>
        <div class="top-right" @click="completeStop(0, 0)">
          查看全部
          <img src="../assets/personage-1.png" alt="" />
        </div>
      </div>

      <div class="three-bottom">
        <div
          class="bottom-list"
          v-for="(item, index) in 3"
          :key="index"
          @click="completeStop(1, index)"
        >
          <div class="list-img">
            <img
              :src="
                index == 0
                  ? require('../assets/personage-2.png')
                  : index == 1
                  ? require('../assets/personage-3.png')
                  : require('../assets/personage-4.png')
              "
              alt=""
            />
          </div>
          <div class="list-title">
            {{ index == 0 ? "待支付" : index == 1 ? "已支付" : "已取消" }}
          </div>
        </div>
      </div>
    </div>

    <div class="personage-fourthly">
      <div
        class="fourthly-list"
        v-for="(item, index) in 3"
        :key="index"
        @click="amendMet(index)"
      >
        <div class="list-img">
          <img
            :class="{ imgs: index == 0, imgs1: index == 1, imgs2: index == 2 }"
            :src="
              index == 0
                ? require('../assets/personage-5.png')
                : index == 1
                ? require('../assets/personage-6.png')
                : require('../assets/personage-7.png')
            "
            alt=""
          />
        </div>

        <div class="list">
          <div class="list-left">
            {{
              index == 0 ? "个人资料" : index == 1 ? "修改手机号码" : "修改密码"
            }}
          </div>

          <div class="list-right">
            {{ index == 0 ? "去查看" : "去修改" }}
            <img src="../assets/personage-1.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="personage-button" @click="logOutMet">退出登录</div>
  </div>
</template>

<script>
import navBar from "../components/nav-bar.vue";
import tabBar from "../components/tab-bar.vue";

import { userInfoGet } from "../api/index";

export default {
  components: { navBar, tabBar },
  data() {
    return {
      infoObj: {},
    };
  },
  created() {
    this.personageInfo();
  },
  mounted() {
    this.backTop()
    window.onload = function () {
      document.addEventListener("touchstart", function (e) {
        if (e.touches.length > 1) {
          e.preventDefault();
        }
      });
      document.addEventListener("gesturestart", function (e) {
        e.preventDefault();
      });
    };
  },
  methods: {
    backTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    personageInfo() {
      let params = {};

      userInfoGet(params).then((res) => {
        if (res.code == 200) {
          this.infoObj = res.result;
        }
      });
    },
    // 全部
    completeStop(num, index) {
      this.$router.push({
        path: "/personageOrder",
        query: {
          index: num == 0 ? index : index + 1,
        },
      });
    },
    amendMet(index) {
      if (index == 0) {
        this.$router.push({
          path: "/PersonageData",
        });
      } else if (index == 1) {
        this.$router.push({
          path: "/personagePhone",
        });
      } else if (index == 2) {
        this.$router.push({
          path: "/personageAlterPassword",
        });
      }
    },
    logOutMet() {
      localStorage.clear();
      this.$router.replace('/register')
    }
  },
};
</script>

<style lang="less" scoped>
.personage {
  min-height: 100vh;
  background: #f5f5f5;
  position: relative;

  .personage-back {
    width: 100%;
    height: 322.5px;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
  }

  .personage-first {
    height: 50px;
    width: 100%;
    position: relative;
  }

  .personage-second {
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .second-head {
      text-align: center;
      .head-img {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        margin: 0 auto;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .head-title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 19px;
        color: #222222;
        margin-top: 11px;
      }
    }
  }

  .personage-three {
    position: relative;
    margin: 0 10px;
    padding: 16px 14px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 10px;

    .three-top {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .top-left {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 15px;
        color: #222222;
      }
      .top-right {
        display: flex;
        align-items: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #888888;

        img {
          width: 20px;
          margin-left: 10px;
        }
      }
    }

    .three-bottom {
      margin-top: 20px;
      display: flex;
      justify-content: space-around;

      .bottom-list {
        .list-img {
          width: 23px;
          height: 23px;
          margin: 0 auto;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .list-title {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #222222;
          margin-top: 9px;
        }
      }
    }
  }

  .personage-fourthly {
    position: relative;
    margin: 0 10px;
    margin-top: 10px;
    background: #fff;
    border-radius: 10px;

    .fourthly-list {
      height: 68px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 7px 0 60px;
      box-sizing: border-box;
      position: relative;

      .list-img {
        position: absolute;
        width: 60px;
        height: 100%;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .imgs {
          width: 21px;
          height: 21px;
        }
        .imgs1 {
          width: 27.5px;
          height: 27.5px;
        }
        .imgs2 {
          width: 25px;
          height: 25px;
        }
      }

      .list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid#E0E0E0;
        height: 68px;
        width: 100%;

        .list-left {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #222222;
        }

        .list-right {
          display: flex;
          align-items: center;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #999999;

          img {
            width: 20px;
            margin-left: 10px;
          }
        }
      }
    }

    .fourthly-list:last-child {
      .list {
        border-bottom: 0;
      }
    }
  }

  .personage-button {
    margin: 20px 10px 0;
    height: 46px;
    border-radius: 5px;
    font-family: PingFang-SC, PingFang-SC;
    font-weight: 800;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1px;
    background: linear-gradient(90deg, #609ce6 0%, #5084da 100%);
    color: #ffffff;
  }
}
</style>